import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import axios from 'axios';
import CondoAgreementsPresentational from './CondoAgreementsPresentational';
import { getSignedUrl } from '../../../lib/fetch/files';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const CondoAgreements = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const { profile, data: condo } = props;

  const [agreements, setAgreements] = useState([]);
  const [availabilityAgreements, setAvailabilityAgreements] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (condo._id) {
      fetchAgreements();
      fetchAvailabilityAgreements();
    }
  }, []);

  const fetchAgreements = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/condo/${condo._id}`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setAgreements(data.agreements);
    setTotal(+headers['x-total-count']);
  };

  const fetchAvailabilityAgreements = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/avaiability/${
        condo.matchingAdmin.length > 0
          ? condo.matchingAdmin[0].id
          : condo.admins[condo.admins.length - 1].id
      }`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setAvailabilityAgreements(data.agreements);
    setTotal(+headers['x-total-count']);
  };

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  return (
    <CondoAgreementsPresentational
      data={agreements}
      availabilityAgreements={availabilityAgreements}
      total={total}
      profile={profile}
      condo={condo}
      handleFileListView={handleFileListView}
      fetchAgreements={fetchAgreements}
      fetchAvailabilityAgreements={fetchAvailabilityAgreements}
    />
  );
};

export default CondoAgreements;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContractEONType,
  CurrencyField,
  DataTable,
  Field,
} from '../../commons/DataTable';

function RecurrentContractsTabel({ data, total = 0 }) {
  const [t] = useTranslation('COMMON');

  const [recurrentContracts, setRecurrentContracts] = useState(data || []);
  const [query, setQuery] = useState({
    limit: 50,
    offset: 0,
  });

  const { limit, offset } = query;

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  useEffect(() => {
    const paginatedRecurrentContracts = recurrentContracts.slice(
      offset,
      offset + limit
    );
    setRecurrentContracts(paginatedRecurrentContracts);
  }, [offset, limit]);

  return (
    <DataTable
      data={recurrentContracts}
      total={data.lenght}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('NO_DATA')}
    >
      <Field title="Nome Cliente" source="condo_name" className="text-left" />
      <Field title="Partita IVA Cliente" source="condo_vat_cf" />
      <ContractEONType title="Nome prodotto" source="product_name" />
      <Field title="POD/PDR" source="pod_pdr" />
      <CurrencyField
        title="Quota responsabile"
        source="adminShare"
        className="txt-table-right"
      />
    </DataTable>
  );
}

export default RecurrentContractsTabel;

import React, { useState } from 'react';
import { CustomInput, Form } from 'reactstrap';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';

function SwitchField(props) {
  const [t] = useTranslation('FILES');
  const {
    value = false,
    maxLength,
    index,
    data,
    profile = {},
    disabled = false,
    getProfileDocuments,
  } = props;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token } = {}] = useAuth();

  const [isNeeded, setIsNeeded] = useState(
    !data._id || value === 'false' || value === false ? false : true
  );

  const updateFile = async () => {
    try {
      showLoading();

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/documents/${data._id}`,
        {
          needed: value === 'false' || value === false ? false : true,
        },
        {
          headers: { Authorization: token },
        }
      );

      if (response instanceof Error) {
        hideLoading();
        alert.error(t('COMMON:GENERIC_ERROR'));
        return { error: response };
      }

      hideLoading();
      getProfileDocuments();
      alert.success(t('COMMON:SUCCESS_UPDATE'));
      return response;
    } catch (error) {
      // Sentry TODO
      return { error };
    }
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          zIndex: '0',
        }}
      >
        <CustomInput
          type="checkbox"
          id={`switch${index}`}
          name="customSwitch"
          checked={isNeeded}
          onChange={() => {
            updateFile();
            setIsNeeded(!isNeeded);
          }}
          disabled={
            data.uploadedBy === 'EXTRA'
              ? true
              : data.type?.required
              ? true
              : profile._id === data.profileId ||
                (profile.type === 'ADMIN_COLLAB' &&
                  profile.adminId === data.profileId)
              ? false
              : true
          }
        />
      </div>
    </>
  );
}

export default SwitchField;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import CondoBalancesPresentational from './CondoBalancesPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const CondoBalances = ({ data = {}, condoId }) => {
  const [{ token, profile = {} } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [condoBalances, setCondoBalances] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
  });

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const { limit, offset } = query;

  const fetchCondoBalances = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/condos/balances/${condoId}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCondoBalances(data.condos[0].balances);
    setTotal(+headers['x-total-count']);
  };

  useEffect(() => {
    if (condoId) {
      fetchCondoBalances();
    }
  }, []);

  return (
    <CondoBalancesPresentational
      data={condoBalances}
      total={total}
      profile={profile}
      condo={data}
      onFilter={onFilter}
    />
  );
};

export default CondoBalances;

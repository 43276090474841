import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContFilterYear,
  BtnOutlineOrange,
  BtnOutlineGreen,
  ContPaginationPage,
} from '../../../../styled-components';
import RoyaltyTable from '../RoyaltyTable';
import TotalRevenuesRow from '../TotalTrRevenue';
import variables from '../../../../variables';
import { useAuth } from '../../../../commons/Auth';
import ReportingModal from '../../../../commons/ReportingModal';
import SearchInput from '../../../../commons/SearchInput';
import generateMonthOptions from '../../../../lib/helpers/generateMonthOptions';
import generatePaginationOptions from '../../../../lib/helpers/generatePaginationOptions';
import generateYearOptions from '../../../../lib/helpers/generateYearOptions';
import { USER_ADMIN_TYPES } from '../../../../App';
import RoyaltyTableBackoffice from '../RoyaltyTableBackoffice';
import moment from 'moment';

const VariableRevenuesDetailPresentational = ({
  data = {},
  profile,
  query,
  total,
  onFilter,
  onAddCommercialBonus,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [{ isAdmin, userType = '' }] = useAuth();
  const { categories = {} } = data;
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const alert = useAlert();
  const [amount, setAmount] = useState(0);
  const { variableRevenues = {} } = categories;
  const { data: dataRev = [] } = variableRevenues;
  const origin = window.location.origin;
  let hash = window.location.hash.split('/');
  hash.pop();
  const prevPath =
    origin +
    '/' +
    hash.join('/') +
    `${query.year ? '?year=' + query.year : ''}`;

  const getVariableRevenuesTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce((prev, cur) => {
      const { payments } = cur || {};
      return (
        prev +
        (payments || []).reduce((acc, payment) => {
          return acc + ((payment && payment.gross) || 0);
        }, 0)
      );
    }, 0);
    const netTotals = dataRev.reduce((prev, cur) => prev + cur?.net, 0);
    const grossTotals = dataRev.reduce((prev, cur) => prev + cur?.gross, 0);
    const commercialShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.commercialShare,
      0
    );
    const earnedCommercialShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.earnedCommercialShare,
      0
    );
    const billableCommercialShareTotals = dataRev.reduce((prev, cur) => {
      return prev + cur?.billableCommercialShare;
    }, 0);
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      commercialShareTotals,
      earnedCommercialShareTotals,
      billableCommercialShareTotals,
    };
  };

  const toggle = ({ selectAll: toggleSelectAll = false } = {}) => {
    if (isOpen) {
      setSelectAll(false);
      return setIsOpen(false);
    }

    setSelectAll(toggleSelectAll);

    const validRows = dataRev.map((row, index) => {
      if (row.checked || toggleSelectAll) {
        return {
          ...row,
          checked:
            row.billableCommercialShare -
              row?.iafs?.reduce((acc, iaf) => acc + iaf.amount, 0) >
            0,
        };
      }
      return row;
    });

    const checkedRows = validRows.filter(({ checked }) => checked);

    if (checkedRows.length) {
      const billableTotal = checkedRows.reduce(
        (acc, { billableCommercialShare = 0 }) => acc + billableCommercialShare,
        0
      );
      const alreadyBilled = checkedRows.reduce((acc, { iafs = [] }) => {
        return (
          acc +
            iafs.reduce((acc, { amount = 0 }) => {
              return acc + amount || 0;
            }, 0) || 0
        );
      }, 0);
      setAmount(+(billableTotal - alreadyBilled).toFixed(2));
      setIsOpen(!isOpen);
    } else {
      const message = toggleSelectAll
        ? t('ROYALTY:NO_ROW_IAF_SELECTABLE')
        : t('ROYALTY:SELECT_AT_LEAST_ONE');
      alert.error(message);
    }
  };

  const onSubmit = (formData) => {
    const validRows = dataRev.map((row, index) => {
      if (row.checked || selectAll) {
        return {
          ...row,
          checked:
            row.billableCommercialShare -
              row?.iafs?.reduce((acc, iaf) => acc + iaf.amount, 0) >
            0,
        };
      }
      return row;
    });

    const checkedRows = validRows.filter(({ checked }) => checked);

    const accountabilityYear = moment(checkedRows[0]?.accountability).year();
    for (const row of checkedRows) {
      const rowYear = moment(row.accountability).year();
      if (rowYear !== accountabilityYear) {
        alert.error(
          'Le fatture selezionate presentano anni di rendicontazione differenti.'
        );
        toggle();
        return;
      }
    }

    const data = {
      ...formData,
      iafType: 'INVOICES',
      invoices: checkedRows.map(
        ({
          _id,
          accountability,
          admin,
          earnedCommercialShare,
          commercialShare,
          net,
          supplier,
          customer,
          adminQuery,
          date,
          commercialSharePerc,
          number,
        }) => ({
          _id,
          accountability: accountability || undefined,
          admin,
          earnedCommercialShare,
          commercialShare,
          net,
          supplier,
          customer,
          adminQuery,
          date,
          commercialSharePerc,
          number,
        })
      ),
      amount,
    };
    onAddCommercialBonus(data);
    toggle();
  };

  return (
    <>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('TITLE_FILTER_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          <div
            style={{
              fontWeight: 700,
              color: '#333333',
              marginBottom: '20px',
              fontSize: '20px',
            }}
          >
            <a
              style={{
                color: variables.primary,
              }}
              href={prevPath}
            >
              Rendicontazioni
            </a>{' '}
            / <a>{t('ROYALTY:TITLE')}</a>
          </div>
          <ContAffiliation key={1}>
            <Row>
              <Col xs="6">
                <H5Styled>{t('ROYALTY:TITLE')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right">
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={toggle}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE')}
                  </BtnOutlineGreen>
                )}
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={() => toggle({ selectAll: true })}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE_ALL')}
                  </BtnOutlineGreen>
                )}
              </Col>
            </Row>
            <br />
            <Row className="compensi-variabili-resp">
              <Col sm="4" md="4" lg="4">
                <SearchInput
                  query={{ search: query && query.adminQuery }}
                  onFilter={({ search }) => onFilter({ adminQuery: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="4" md="4" lg="4">
                <SearchInput
                  query={{ search: query && query.supplier }}
                  onFilter={({ search }) => onFilter({ supplier: search })}
                  label={t('SEARCH:BY_SUPPLIER')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="4" md="4" lg="4">
                <SearchInput
                  query={{ search: query && query.number }}
                  onFilter={({ search }) => onFilter({ number: search })}
                  label={t('SEARCH:INVOICE_NUMBER')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectAccountabilityYear"
                          name="accountabilityYear"
                          value={query && query.accountabilityYear}
                          onChange={({
                            target: { value: accountabilityYear },
                          }) => {
                            onFilter({ accountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col sm="6" md="6" lg="6">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="accountabilityMonth"
                          name="accountabilityMonth"
                          disabled={!query.accountabilityYear}
                          value={
                            query && query.accountabilityMonth === null
                              ? ''
                              : query.accountabilityMonth
                          }
                          onChange={({
                            target: { value: accountabilityMonth },
                          }) => onFilter({ accountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
            <br />
            {(userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
              <RoyaltyTable
                data={dataRev || []}
                total={total}
                query={query}
                onFilter={onFilter}
                showEmptyTableMessage={true}
                bottomRow={
                  <TotalRevenuesRow
                    totals={getVariableRevenuesTotals(dataRev)}
                    route="variable-revenues"
                    activeRoute={false}
                  />
                }
              />
            )) || (
              <RoyaltyTableBackoffice
                data={dataRev || []}
                total={total}
                query={query}
                onFilter={onFilter}
                showEmptyTableMessage={true}
                bottomRow={
                  <TotalRevenuesRow
                    totals={getVariableRevenuesTotals(dataRev)}
                    route="variable-revenues"
                    activeRoute={false}
                  />
                }
              />
            )}

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </ContAffiliation>
        </TabPane>
      </TabContent>
      <ReportingModal
        toggle={toggle}
        isOpen={isOpen}
        onSubmit={onSubmit}
        amount={amount}
        query={query}
      />
    </>
  );
};

export default VariableRevenuesDetailPresentational;

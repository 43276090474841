import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  PaymentsDataField,
  BilledField,
  ContractEONType,
} from '../../../../commons/DataTable';
import CustomCheckbox from '../../../../commons/Checkboxes/CustomCheckboxes';
import { useAuth } from '../../../../commons/Auth';
import IAFCustomCheckboxes from '../../../../commons/Checkboxes/IAFCustomCheckboxes';

function RecurrentEnergyRoyaltyTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  truncateTable,
  showEmptyTableMessage,
  allowedRoles = [],
  onSelectChange,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;
  if (isAdmin === undefined) return null;

  const onCheck = (report) => {
    if (!allowedRoles.includes('superadmin')) {
      return;
    }
    onSelectChange(report);
  };

  return isAdmin ? (
    <DataTable
      offset={offset}
      limit={limit}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      <IAFCustomCheckboxes
        onSelectChange={onCheck}
        onClick={(e) => e.stopPropagation()}
        visible={!truncateTable && isAdmin}
      />

      {!truncateTable && (
        <Field title="Nome Cliente" source="condo_name" className="text-left" />
      )}
      {!truncateTable && (
        <Field title="Partita IVA Cliente" source="condo_vat_cf" />
      )}
      {!truncateTable && (
        <ContractEONType title="Nome prodotto" source="product_name" />
      )}
      {!truncateTable && <Field title="POD/PDR" source="pod_pdr" />}
      {!truncateTable && (
        <Field title="Consumo attuale" source="actual_consumption" />
      )}
      {!truncateTable && (
        <Field title="Consumo da contratto" source="contractual_consumption" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data creazione"
          source="contract_signing_date"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data attivazione"
          source="activation_start_date"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data Scadenza"
          source="contract_end_date"
          format="DD/MM/YYYY"
        />
      )}

      {!truncateTable && (
        <DateTimeField
          title="Data COMPENSO Ricorrente"
          source="dateFee"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && <Field title="Causale" source="denomination" />}
      {!truncateTable && (
        <Field title="Percentuale COMPENSO" source="percentage" />
      )}

      <CurrencyField
        title="Imponibile"
        source="rateable"
        className="txt-table-right"
      />
      <CurrencyField
        title="Profitto Extra"
        source="net"
        className="txt-table-right"
      />
      {/* <CurrencyField
        title="Importo da Incassare"
        source="extraShare"
        className="txt-table-right"
      /> */}
      {/* <PaymentsDataField title="Pagato" source="payments" type="total" /> */}
      <CurrencyField
        title="Quota responsabile"
        source="adminShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Quota responsabile maturata"
        source="earnedAdminShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Diritto a Fatturare"
        source="billableAdminShare"
        className="txt-table-right"
      />
      <BilledField
        title="Invitato a Fatturare"
        className="txt-table-right"
        visible={!truncateTable && isAdmin}
      />
      <Field
        title="Numero IAF"
        className="txt-table-right"
        source="iafs.0.number"
        visible={!truncateTable && isAdmin}
      />
    </DataTable>
  ) : (
    <DataTable
      offset={offset}
      limit={limit}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable && (
        <Field title="Nome Cliente" source="condo_name" className="text-left" />
      )}
      {!truncateTable && (
        <Field title="Partita IVA Cliente" source="condo_vat_cf" />
      )}
      {!truncateTable && (
        <ContractEONType title="Nome prodotto" source="product_name" />
      )}
      {!truncateTable && <Field title="POD/PDR" source="pod_pdr" />}
      {!truncateTable && (
        <Field title="Consumo attuale" source="actual_consumption" />
      )}
      {!truncateTable && (
        <Field title="Consumo da contratto" source="contractual_consumption" />
      )}
      {!truncateTable && <Field title="Causale" source="denomination" />}
      {!truncateTable && (
        <DateTimeField
          title="Data creazione"
          source="contract_signing_date"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data attivazione"
          source="activation_start_date"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data Scadenza"
          source="contract_end_date"
          format="DD/MM/YYYY"
        />
      )}
      <CurrencyField
        title="Quota responsabile"
        source="adminShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Quota responsabile maturata"
        source="earnedAdminShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Diritto a Fatturare"
        source="billableAdminShare"
        className="txt-table-right"
      />
    </DataTable>
  );
}

export default withRouter(RecurrentEnergyRoyaltyTable);

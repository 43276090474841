import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import classnames from 'classnames';
import {
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
  H4Styled,
  H5Styled,
} from '../../styled-components';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import axios from 'axios';
import InvoiceTabel from './InvoiceTabel';
import ContractsTabel from './ContractsTabel';
import RecurrentContractsTabel from './RecurrentContractsTabel';
import { createBonus } from '../../lib/fetch/reports';
import moment from 'moment';

const ModalIafs = ({
  data = [],
  month,
  value,
  year,
  fetchExtraInvoicesByMonth,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [modal, setModal] = useState(false);
  const [dataForIafsInvoices, setDataForIafsInvoices] = useState([]);
  const [dataForIafsContracts, setDataForIafsContracts] = useState([]);
  const [
    dataForIafsRecurrentContracts,
    setDataForIafsRecurrentContracts,
  ] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totIafs, setTotIafs] = useState(0);
  const [totInvoices, setTotInvoices] = useState(0);
  const [totContracts, setTotContracts] = useState(0);
  const [totRecurrentContracts, setTotRecurrentContracts] = useState(0);
  const [sentinel, setSentinel] = useState('invoices');

  const toggle = () => {
    setModal(!modal);
  };

  const CURRENT_YEAR = new Date().getFullYear();
  const CURRENT_MONTH = new Date().getMonth() + 1;

  const getColor = ({ month, totalCount, year }) => {
    const notNull = totalCount !== null;
    if (notNull || year > CURRENT_YEAR) return '';

    if (year < CURRENT_YEAR) return notNull ? '' : 'red';
    if (month < CURRENT_MONTH) return notNull ? '' : 'red';
    return '';
  };

  const confirmPreview = async (bonus) => {
    const { error } = await createBonus({
      token,
      bonus: { ...bonus, profileId: data.profileId },
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
  };

  const handleConfirm = async () => {
    try {
      showLoading();

      if (dataForIafsInvoices.length > 0) {
        await confirmPreview({
          iafType: 'INVOICES',
          amount: totInvoices,
          date: moment.utc().startOf('day').format(),
          accountability: year,
          invoices: dataForIafsInvoices.map(
            ({
              _id,
              accountability,
              adminShare,
              net,
              supplier,
              customer,
              date,
              number,
            }) => ({
              _id,
              accountability: accountability || undefined,
              admin: {
                _id: data.profileId,
                businessName: data.businessName,
              },
              earnedAdminShare: adminShare,
              adminShare,
              net,
              supplier,
              customer,
              date,
              number,
            })
          ),
        });
      }

      if (dataForIafsContracts.length > 0) {
        await confirmPreview({
          iafType: 'ENERGY',
          amount: totContracts,
          date: moment.utc().startOf('day').format(),
          accountability: year,
          contracts: dataForIafsContracts.map(
            ({ _id, admin, condo, adminShare, extraShare, date, number }) => ({
              _id,
              admin,
              condo,
              earnedAdminShare: adminShare,
              adminShare,
              net: extraShare,
              date,
              number,
            })
          ),
        });
      }

      if (dataForIafsRecurrentContracts.length > 0) {
        await confirmPreview({
          iafType: 'RECURRENT_ENERGY',
          amount: totRecurrentContracts,
          date: moment.utc().startOf('day').format(),
          accountability: year,
          recurrentContracts: dataForIafsRecurrentContracts.map(
            ({ _id, condo, adminShare, net, date, number }) => ({
              _id,
              admin: {
                _id: data.profileId,
                businessName: data.businessName,
              },
              condo,
              earnedAdminShare: adminShare,
              adminShare,
              net,
              date,
              number,
            })
          ),
        });
      }

      setDataForIafsInvoices([]);
      setDataForIafsContracts([]);
      setDataForIafsRecurrentContracts([]);
      toggle();
      await fetchExtraInvoicesByMonth(true);

      alert.success(t('COMMON:SUCCESS_UPDATE'));
    } catch (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    const count =
      (data.invoices[month - 1]?.count || 0) +
      (data.contracts[month - 1]?.count || 0) +
      (data.recurrentContracts[month - 1]?.count || 0);
    setTotalCount(count);
    setDataForIafsInvoices(data.invoices[month - 1]?.invoices || []);
    setDataForIafsContracts(data.contracts[month - 1]?.contracts || []);
    setDataForIafsRecurrentContracts(
      data.recurrentContracts[month - 1]?.recurrentContracts || []
    );

    const invoices = data.invoices[month - 1]?.invoices || [];
    const contracts = data.contracts[month - 1]?.contracts || [];
    const recurrentContracts =
      data.recurrentContracts[month - 1]?.recurrentContracts || [];

    const sum = [...invoices, ...contracts, ...recurrentContracts].reduce(
      (acc, item) => acc + (item.adminShare || 0),
      0
    );
    const sumInvoices = invoices.reduce(
      (acc, item) => acc + (item.adminShare || 0),
      0
    );
    const sumContracts = contracts.reduce(
      (acc, item) => acc + (item.adminShare || 0),
      0
    );
    const sumRecurrentContracts = recurrentContracts.reduce(
      (acc, item) => acc + (item.adminShare || 0),
      0
    );

    setTotIafs(sum.toFixed(2));
    setTotInvoices(sumInvoices.toFixed(2));
    setTotContracts(sumContracts.toFixed(2));
    setTotRecurrentContracts(sumRecurrentContracts.toFixed(2));
  }, [data]);

  return (
    <>
      <span
        style={{
          color: getColor({ month, totalCount, year }),
          cursor: 'pointer',
        }}
      >
        <span>Elementi da emettere: {totalCount || 0}</span>
        <br />
        <span>Totale da emettere: € {totIafs}</span>
        <br />
        <br />
        {totalCount > 0 && (
          <div className="flex-check">
            <BtnModalTable
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                toggle();
              }}
            >
              EMETTI IAF
            </BtnModalTable>
          </div>
        )}
      </span>

      <Modal isOpen={modal} className="iafs-modal">
        <ModalHeader toggle={toggle}>
          Anteprima Iaf
          <br />
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Totale da emettere: € {totIafs}
            </h5>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Elementi da emettere: {totalCount}
            </h5>
          </div>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: sentinel === 'invoices' })}
                onClick={() => setSentinel('invoices')}
                style={{ cursor: 'pointer' }}
              >
                Fatture
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: sentinel === 'contracts' })}
                onClick={() => setSentinel('contracts')}
                style={{ cursor: 'pointer' }}
              >
                Energia
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: sentinel === 'recurrentContracts',
                })}
                onClick={() => setSentinel('recurrentContracts')}
                style={{ cursor: 'pointer' }}
              >
                Energia Ricorrente
              </NavLink>
            </NavItem>
          </Nav>

          {sentinel === 'invoices' && dataForIafsInvoices?.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <InvoiceTabel
                data={dataForIafsInvoices}
                onFilter={() => {}}
                query={{
                  limit: dataForIafsInvoices?.length || 50,
                  offset: 0,
                }}
                total={dataForIafsInvoices?.length}
              />
            </div>
          )}

          {sentinel === 'invoices' && dataForIafsInvoices?.length === 0 && (
            <H4Styled style={{ textAlign: 'center', marginTop: '15px' }}>
              Non ci sono fatture su cui emettere iaf
            </H4Styled>
          )}

          {sentinel === 'contracts' && dataForIafsContracts?.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <ContractsTabel
                data={dataForIafsContracts}
                onFilter={() => {}}
                query={{
                  limit: dataForIafsContracts?.length || 50,
                  offset: 0,
                }}
                total={dataForIafsContracts?.length}
              />
            </div>
          )}

          {sentinel === 'contracts' && dataForIafsContracts?.length === 0 && (
            <H4Styled style={{ textAlign: 'center', marginTop: '15px' }}>
              Non ci sono contratti energia su cui emettere iaf
            </H4Styled>
          )}

          {sentinel === 'recurrentContracts' &&
            dataForIafsRecurrentContracts?.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                <RecurrentContractsTabel
                  data={dataForIafsRecurrentContracts}
                  onFilter={() => {}}
                  query={{
                    limit: dataForIafsRecurrentContracts?.length || 50,
                    offset: 0,
                  }}
                  total={dataForIafsRecurrentContracts?.length}
                />
              </div>
            )}

          {sentinel === 'recurrentContracts' &&
            dataForIafsRecurrentContracts?.length === 0 && (
              <H4Styled style={{ textAlign: 'center', marginTop: '15px' }}>
                Non ci sono contratti energia ricorrenti su cui emettere iaf
              </H4Styled>
            )}
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          {totalCount > 0 && (
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={handleConfirm}
            >
              Conferma Iaf
            </BtnOutlineGreen>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalIafs;

import React, { useEffect, useState } from 'react';
import { useLoading } from '../../../../commons/Loading';
import { useTranslation } from 'react-i18next';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { getCondoReportsVariableRevenuesRecurrentEnergy } from '../../../../lib/fetch/reports';
import VarRevRecurrentEnergyDetailPaginatedPresentational from './VarRevRecurrentEnergyDetailPresentational';
import { useAuth } from '../../../../commons/Auth';

const VarRevRecurrentEnergyDetail = (props) => {
  const { profile = {}, condo, condoId } = props;

  const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
  const { _id: profileId } = profile;
  const { showLoading, hideLoading } = useLoading();
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });

  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: withDefault(NumberParam, new Date().getFullYear()),
    energyRecurrentPod: withDefault(StringParam, ''),
    energyRecurrentCondoVat: withDefault(StringParam, ''),
    energyRecurrentAccountabilityYear: NumberParam,
    energyRecurrentAccountabilityMonth: NumberParam,
  });

  const {
    limit,
    offset,
    year,
    search,
    energyRecurrentPod,
    energyRecurrentCondoVat,
    energyRecurrentAccountabilityYear,
    energyRecurrentAccountabilityMonth,
  } = query;

  const fetchCondoReportsRevenuesRecurrentEnergy = async () => {
    showLoading();
    const response = await getCondoReportsVariableRevenuesRecurrentEnergy({
      limit,
      offset,
      year,
      search,
      energyRecurrentPod,
      energyRecurrentCondoVat,
      energyRecurrentAccountabilityYear,
      energyRecurrentAccountabilityMonth,
      token,
      condoId,
      condoVatCode: condo.vatCode,
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setReports(data);
    setTotal(data.categories?.variableRevenuesRecurrentEnergy?.count);
  };

  useEffect(() => {
    if (condo.vatCode) {
      fetchCondoReportsRevenuesRecurrentEnergy();
    }
  }, [
    limit,
    offset,
    year,
    search,
    energyRecurrentPod,
    energyRecurrentCondoVat,
    energyRecurrentAccountabilityYear,
    energyRecurrentAccountabilityMonth,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.energyRecurrentAccountabilityYear &&
        queryDiff.energyRecurrentAccountabilityYear !==
          energyRecurrentAccountabilityYear) ||
      queryDiff.energyRecurrentAccountabilityYear === ''
    ) {
      queryDiff.energyRecurrentAccountabilityMonth = '';
    }

    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  return (
    <VarRevRecurrentEnergyDetailPaginatedPresentational
      data={reports}
      onFilter={onFilter}
      total={total}
      profileType={profile.type}
      query={query}
      {...props}
    />
  );
};

export default VarRevRecurrentEnergyDetail;

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import NetworkPresentational from './NetworkPresentational';
import axios from 'axios';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Network = ({ profile }) => {
  const [{ token } = {}] = useAuth();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    type: withDefault(StringParam, ''),
    profileStatus: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    year: withDefault(NumberParam, new Date().getFullYear()),
  });

  const { search, offset, limit, type, year } = query;

  useEffect(() => {
    const fetchProfiles = async () => {
      showLoading();

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/network`,
        {
          headers: { Authorization: token },
          params: {
            search,
            offset,
            limit,
            type,
            year,
          },
        }
      );

      hideLoading();

      if (response instanceof Error) {
        return alert.error(t('COMMON:GENERIC_ERROR'));
      }

      const { data = [], headers = {} } = response;

      setData(data);
      setTotal(Number(headers['x-total-count']));
      hideLoading();
    };

    fetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, offset, limit, type, year]);

  function onFilter(query) {
    if (
      (query.search && query.search !== search) || // first search
      (search && query.search === '') || // search deleted
      (query.year && query.year !== year) || // first search
      (year && query.year === '') ||
      (query.type && query.type !== type) ||
      (query.limit && query.limit !== limit)
    ) {
      query.offset = 0; // return to first page
    }
    setQuery(query);
  }

  return (
    <NetworkPresentational
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
    />
  );
};

export default Network;

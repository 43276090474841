import React, { useEffect, useState } from 'react';
import { useLoading } from '../../../../commons/Loading';
import { useAuth } from '../../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { getCondoReportsVariableRevenues } from '../../../../lib/fetch/reports';
import VariableRevenuesDetailPresentational from './VariableRevenuesDetailPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const VariableRevenuesDetail = (props) => {
  const { profile = {}, condoId, condo } = props;
  const { _id: profileId } = profile;
  const { showLoading, hideLoading } = useLoading();
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });

  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    supplier: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    supplier,
    number,
    year,
    accountabilityYear,
    accountabilityMonth,
  } = query;

  const fetchCondoReportsRevenues = async () => {
    showLoading();
    const response = await getCondoReportsVariableRevenues({
      limit,
      offset,
      condoId,
      condoVatCode: condo.vatCode,
      year,
      accountabilityYear,
      accountabilityMonth,
      token,
      supplier,
      number,
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setReports(data);
    setTotal(data.categories?.variableRevenues?.count);
  };

  useEffect(() => {
    if (condo.vatCode) {
      fetchCondoReportsRevenues();
    }
  }, [
    limit,
    offset,
    year,
    accountabilityYear,
    accountabilityMonth,
    supplier,
    number,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }

    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  return (
    <VariableRevenuesDetailPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      total={total}
      profile={profile}
      condoId={condoId}
      {...props}
    />
  );
};

export default VariableRevenuesDetail;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  ContractEONType,
  CurrencyField,
} from '../../commons/DataTable';
import { USER_ADMIN_TYPES } from '../../App';
import CustomerTypeField from '../../commons/DataTable/CustomerTypeField';
import TotalTr from './TotalTr';

function ContractsTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  userType = '',
  profileType,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  const getTotals = (data) => {
    const extraShareTotals = data.reduce(
      (prev, cur) => prev + cur.extraShare,
      0
    );
    const adminShareTotals = data.reduce(
      (prev, cur) => prev + cur.adminShare,
      0
    );
    const contractValueTotals = data.reduce(
      (prev, cur) => prev + cur.contract_value,
      0
    );

    return {
      extraShareTotals,
      adminShareTotals,
      contractValueTotals,
    };
  };

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
      bottomRow={<TotalTr profileType={profileType} totals={getTotals(data)} />}
    >
      <Field title="POD/PDR" source="pod_pdr" />
      <Field title="Nome cliente" source="condo_name" className="text-left" />
      <CustomerTypeField title="Tipo Cliente" source="customerType" />
      <Field title="Partita iva responsabile" source="condo_admin_cf" />
      <Field title="Partita IVA cliente" source="condo_vat_cf" />
      <DateTimeField
        title="Data creazione"
        source="contract_signing_date"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Data attivazione"
        source="activation_start_date"
        format="DD/MM/YYYY"
      />
      <Field title="Consumo da contratto" source="contractual_consumption" />
      <Field title="Consumo attuale" source="actual_consumption" />
      <ContractEONType title="Nome prodotto" source="product_name" />
      <Field title="Responsabile" source="admin.name" />
      <DateTimeField
        title="Data Scadenza"
        source="contract_end_date"
        format="DD/MM/YYYY"
      />
      <CurrencyField title="Imponibile" source="contract_value" />
      <CurrencyField title="Profitto Extra" source="extraShare" />
      <CurrencyField title="Profitto Responsabile" source="adminShare" />
    </DataTable>
  );
}

export default ContractsTable;

import React, { useEffect, useState } from 'react';
import VarRevEnergyDetailPresentational from './VarRevEnergyDetailPresentational';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { getReportsVariableRevenuesEnergy } from '../../../lib/fetch/reports';
import { useAlert } from 'react-alert';

const VarRevEnergyDetail = (props) => {
  const { profile = {} } = props;

  const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
  const { _id: profileId } = profile;
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });

  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: withDefault(NumberParam, new Date().getFullYear()),
    energyPod: withDefault(StringParam, ''),
    energyCondoVat: withDefault(StringParam, ''),
    energyAccountabilityYear: NumberParam,
    energyAccountabilityMonth: NumberParam,
    customerType: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    customerType,
  } = query;

  const [fetchReportsRevenuesEnergy] = useDebouncedCallback(async () => {
    showLoading();
    const response = await getReportsVariableRevenuesEnergy({
      limit,
      offset,
      search,
      year,
      energyPod,
      energyCondoVat,
      energyAccountabilityYear,
      energyAccountabilityMonth,
      customerType,
      profileId,
      token,
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setReports(data);
    setTotal(data.categories?.variableRevenuesEnergy?.count);
  }, 1000);

  useEffect(() => {
    fetchReportsRevenuesEnergy();
  }, [
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    customerType,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.energyAccountabilityYear &&
        queryDiff.energyAccountabilityYear !== energyAccountabilityYear) ||
      queryDiff.energyAccountabilityYear === ''
    ) {
      queryDiff.energyAccountabilityMonth = '';
    }

    if (
      (queryDiff.customerType && queryDiff.customerType !== customerType) || // first search
      (customerType && queryDiff.customerType === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  return (
    <VarRevEnergyDetailPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      total={total}
      profileType={profile.type}
      {...props}
    />
  );
};

export default VarRevEnergyDetail;

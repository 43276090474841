import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../commons/Loading';
import { Col, Row } from 'reactstrap';
import RenewSubscriptionButton from './RenewSubscriptionButton';
import SubscriptionTable from './SubscriptionTable';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { getAllProfileSubscriptions } from '../../../lib/fetch/subscriptions';
import axios from 'axios';
import moment from 'moment';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const MySubscriptions = (props) => {
  const [{ token, isAdmin, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [total, setTotal] = useState(0);

  const [profileSubscriptions, setProfileSubscriptions] = useState([]);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
  });

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const { limit, offset } = query;

  const fetchProfileSubscriptions = async () => {
    showLoading();

    const { data, error, headers = {} } = await getAllProfileSubscriptions({
      profileId: profile._id,
      limit,
      offset,
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfileSubscriptions(data.subscriptions);
    setTotal(+headers['x-total-count']);
    hideLoading();
  };

  useEffect(() => {
    fetchProfileSubscriptions();
  }, [limit, offset]);

  return (
    <>
      <section>
        <SubscriptionTable
          data={profileSubscriptions}
          total={total}
          query={query}
          limit={limit}
          offset={offset}
          onFilter={onFilter}
          onStatusChanged={() => fetchProfileSubscriptions()}
        />
      </section>
    </>
  );
};

export default MySubscriptions;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../commons/Auth';
import AgreementEditorPresentational from './AgreementsEditorPresentational';
import './AgreementsEditor.scss';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import { uploadAgreementFirmedFile } from '../../lib/fetch/agreements';
import axios from 'axios';

const AgreementsEditor = ({ profile }) => {
  const { id: agreementId } = useParams();
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const [{ token, isAdmin } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const [agreement, setAgreement] = useState({});

  const fetchAgreement = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/single/${agreementId}`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;

    setAgreement(data);
  };

  const handleSave = async (content, comments, toggle, forComments) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/agreements/editor/${agreementId}`,
      { content, comments, forComments, senderType: profile.type },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    toggle();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchAgreement();
  };

  const handleSaveRevision = async (content, comments, toggle) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/agreements/revision/${agreementId}`,
      { content, comments },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    toggle();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchAgreement();
  };

  const handleSaveFirmed = async (newContract, toggle) => {
    showLoading();

    const { error, data } = await uploadAgreementFirmedFile({
      data: newContract,
      token,
      agreementId,
    });

    hideLoading();

    if (error) {
      toggle();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    toggle();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchAgreement();
  };

  useEffect(() => {
    fetchAgreement();
  }, []);

  return (
    <AgreementEditorPresentational
      profile={profile}
      agreement={agreement}
      handleSave={handleSave}
      handleSaveRevision={handleSaveRevision}
      handleSaveFirmed={handleSaveFirmed}
    />
  );
};

export default AgreementsEditor;

import React, { useState, useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';

import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';

import contractsAPIs from '../../../lib/fetch/contractsAPIs';
import EnergyRecurringPresentational from './EnergyRecurringPresentational';
import { useAuth } from '../../../commons/Auth';
import axios from 'axios';
import moment from 'moment';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const ContractsRecurring = ({ profile }) => {
  const [contracts, setContracts] = useState([]);
  const [total, setTotal] = useState(0);
  const [totPod, setTotPod] = useState(0);
  const [totPdr, setTotPdr] = useState(0);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  const [selectedContracts, setSelectedContracts] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  const [selectedAll, setSelectedAll] = useState(false);
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    pod_pdr: withDefault(StringParam, ''),
    condo_admin_cf: withDefault(StringParam, ''),
    condo_vat_cf: withDefault(StringParam, ''),
    address: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    dateFeeYear: NumberParam,
    dateFeeMonth: NumberParam,
  });

  const {
    limit,
    offset,
    admin,
    condo_vat_cf,
    condo_admin_cf,
    pod_pdr,
    customer,
    dateFeeYear,
    dateFeeMonth,
  } = query;

  function onFilter(queryDiff) {
    if (
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.pod_pdr && queryDiff.pod_pdr !== pod_pdr) || // first search
      (pod_pdr && queryDiff.pod_pdr === '') ||
      (queryDiff.dateFeeYear && queryDiff.dateFeeYear !== dateFeeYear) || // first search
      (dateFeeYear && queryDiff.dateFeeYear === '') || // search deleted
      (queryDiff.dateFeeMonth && queryDiff.dateFeeMonth !== dateFeeMonth) || // first search
      (dateFeeMonth && queryDiff.dateFeeMonth === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.condo_vat_cf && queryDiff.condo_vat_cf !== condo_vat_cf) || // first search
      (condo_vat_cf && queryDiff.condo_vat_cf === '') || // search deleted
      (queryDiff.condo_admin_cf &&
        queryDiff.condo_admin_cf !== condo_admin_cf) || // first search
      (condo_admin_cf && queryDiff.condo_admin_cf === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const fetchContracts = async (query) => {
    showLoading();

    const {
      data,
      total: _total,
      totPdr,
      totPod,
    } = await contractsAPIs.findUserRecurrentContracts({
      token,
      query,
      userId: profile._id,
    });
    setTotal(Number(_total || 0));
    setTotPod(totPod);
    setTotPdr(totPdr);
    setContracts(data);

    hideLoading();
  };

  useEffect(() => {
    fetchContracts({
      pod_pdr,
      condo_vat_cf,
      condo_admin_cf,
      offset,
      limit,
      customer,
      dateFeeYear,
      dateFeeMonth,
    });
  }, [
    condo_vat_cf,
    pod_pdr,
    condo_admin_cf,
    offset,
    limit,
    customer,
    dateFeeYear,
    dateFeeMonth,
  ]);

  const onSelectChange = (row) => {
    if (selectedContracts.includes(row._id)) {
      setSelectedAll(false);
      setCheckedRows((checkedRows) =>
        checkedRows.filter(({ _id }) => _id !== row._id)
      );
      return setSelectedContracts((selectedContracts) =>
        selectedContracts.filter((contractId) => contractId !== row._id)
      );
    }
    setSelectedContracts((selectedContracts) => [
      ...selectedContracts,
      row._id,
    ]);
    setCheckedRows((checkedRows) => [...checkedRows, row]);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      setCheckedRows([]);
      return setSelectedContracts([]);
    }
    setCheckedRows(
      contracts.filter((contract) => contract.pinvInvoice?.length === 0)
    );
    setSelectedContracts(
      contracts
        .filter((contract) => contract.pinvInvoice?.length === 0)
        .map(({ _id }) => _id)
    );
  };

  const onInvoice = async () => {
    showLoading();

    const dataFeeYear = moment(checkedRows[0]?.dateFee).year();

    for (const row of checkedRows) {
      const rowYear = moment(row.dateFee).year();
      if (rowYear !== dataFeeYear) {
        alert.error(
          'I contratti selezionati presentano anni di rinnovo differenti.'
        );
        hideLoading();
        return;
      }
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${profile._id}/contracts/recurrent`,
      {
        selectedContracts,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));

    fetchContracts({ pod_pdr, condo_vat_cf, condo_admin_cf, offset, limit });
    setSelectedContracts([]);
    setSelectedAll(false);
  };

  return (
    <EnergyRecurringPresentational
      data={contracts}
      profile={profile}
      query={query}
      total={total}
      totPod={totPod}
      totPdr={totPdr}
      onFilter={onFilter}
      selectedContracts={selectedContracts}
      onInvoice={onInvoice}
      selectedAll={selectedAll}
      onSelectAll={onSelectAll}
      onSelectChange={onSelectChange}
      onStatusChanged={() => fetchContracts()}
    />
  );
};

export default ContractsRecurring;

import React, { useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { BtnOutlineGreen, BtnOutlineRed } from '../../../styled-components';
import { useAlert } from 'react-alert';
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';

function ActionsField(props) {
  const { data, invoiceSubscription, onStatusChanged } = props;

  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('SUBSCRIPTIONS');

  const [modal, setModal] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });

  const toggle = () => {
    setModal(!modal);
  };

  const handleNewExtraInvoice = async () => {
    invoiceSubscription(data._id, data.cost);
  };

  return (
    <>
      <div className="flex-check">
        <BtnOutlineGreen
          type="button"
          className="uppercase"
          onClick={(event) => {
            event.stopPropagation();
            toggle();
          }}
          disabled={data.pinvInvoice?.length > 0}
        >
          FATTURA
        </BtnOutlineGreen>

        <BtnOutlineGreen
          type="button"
          className="uppercase"
          onClick={() =>
            setModalState({
              isOpen: true,
              selectedId: data.pinvInvoice[0]?._id,
            })
          }
          disabled={
            !data.pinvInvoice ||
            data.pinvInvoice.length === 0 ||
            data.pinvInvoice[0]?.invoicePinv?.status !== 'to_deliver'
          }
        >
          Visualizza bozza fattura
        </BtnOutlineGreen>
      </div>

      <Modal isOpen={modal} toggle={toggle} className="extra-invoice-modal">
        <ModalHeader toggle={toggle}>{t('EXTRA_INVOICE_CONFIRM')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleNewExtraInvoice();
            toggle();
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <Row>
                  <Col sm="12">
                    <p className="mb-2" id="invoice-modal-text">
                      Sarà creata una fattura, continuare?
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>

      <ModalInvoiceDetail
        modalState={modalState}
        setModalState={setModalState}
        onStatusChanged={onStatusChanged}
      />
    </>
  );
}

export default ActionsField;

import React from 'react';
import { useTranslation } from 'react-i18next';
import AddContractStepper from './AddContractStepper';

function ActionsFieldRegister({
  data,
  profile,
  documentTypes,
  agreementTypes,
  suppliers,
  documents,
  totalDocuments,
  limitDocuments,
  offsetDocuments,
  onFilterDocuments,
  onSelectChange,
  onSelectAll,
  selectedAll,
  selectedDocuments,
  activityId,
  uploadAgreements,
  setSelectedSupplier,
  setSelectedCondo,
}) {
  const [t] = useTranslation('ACTIVITIES');

  return (
    <React.Fragment>
      <div className="flex-check">
        <AddContractStepper
          condo={data}
          profile={profile}
          documentTypes={documentTypes}
          agreementTypes={agreementTypes}
          suppliers={suppliers}
          documents={documents}
          onFilter={onFilterDocuments}
          total={totalDocuments}
          limit={limitDocuments}
          offset={offsetDocuments}
          onSelectChange={onSelectChange}
          onSelectAll={onSelectAll}
          selectedAll={selectedAll}
          selectedDocuments={selectedDocuments}
          activityId={activityId}
          condoId={data._id}
          uploadAgreements={uploadAgreements}
          setSelectedSupplier={setSelectedSupplier}
          setSelectedCondo={setSelectedCondo}
        />
        {/* <Link to={`#`}>
          <BtnModalTable type="button" className="mx-2 px-2">
            {t('REGISTER')}
          </BtnModalTable>
        </Link> */}
      </div>
    </React.Fragment>
  );
}

export default ActionsFieldRegister;

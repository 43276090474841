import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import RoyaltiesPresentational from './RoyaltiesPresentational';
import { profileScoreFormatter } from '../../lib/helpers/profileScoreFormatter';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Royalties = ({ profile }) => {
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [profileScore, setProfileScore] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [dataExport, setDataExport] = useState([]);
  const [adminCollabs, setAdminCollabs] = useState([]);

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    search: withDefault(
      StringParam,
      localStorage.getItem('myCompensiVariabiliSearch')
    ),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    adminCollab: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    paid: withDefault(StringParam, ''),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
  });

  const {
    all,
    limit,
    offset,
    search,
    admin,
    customer,
    number,
    supplier,
    adminCollab,
    year,
    paid,
    accountabilityMonth,
    accountabilityYear,
  } = query;

  useEffect(() => {
    fetchInvoices();
    fetchAdminCollabs();
  }, [
    all,
    limit,
    offset,
    search,
    admin,
    customer,
    number,
    supplier,
    adminCollab,
    year,
    paid,
    accountabilityMonth,
    accountabilityYear,
  ]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          all,
          limit,
          offset,
          search,
          admin,
          customer,
          number,
          supplier,
          adminCollab,
          year,
          paid,
          accountabilityMonth,
          accountabilityYear,
        },
      }
    );

    const scores = await axios.get(`${process.env.REACT_APP_API_URL}/scores`, {
      headers: { Authorization: token },
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    if (scores instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setInvoices(data);
    setProfileScore(
      profileScoreFormatter(scores.data, scores.data.type || 'auto')
    );
    setTotal(+headers['x-total-count']);
  };

  const fetchInvoicesExport = async () => {
    showLoading();
    setIsReady(false);

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          all,
          limit: 5000,
          offset: 0,
          search,
          admin,
          customer,
          number,
          supplier,
          adminCollab,
          year,
          paid,
          accountabilityMonth,
          accountabilityYear,
        },
      }
    );

    const scores = await axios.get(`${process.env.REACT_APP_API_URL}/scores`, {
      headers: { Authorization: token },
    });

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    if (scores instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setDataExport(data);
    setIsReady(true);
    hideLoading();
  };

  function onFilter(queryDiff) {
    setSelectedInvoices([]);

    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myCompensiVariabiliSearch', queryDiff.search);
    }

    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.paid && queryDiff.paid !== paid) || // first search
      (paid && queryDiff.paid === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.adminCollab && queryDiff.adminCollab !== adminCollab) || // first search
      (adminCollab && queryDiff.adminCollab === '') || // search deleted
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const onSelectChange = (_id) => {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  };

  const onSelectAll = (selectAll) => {
    if (!selectAll) {
      return setSelectedInvoices([]);
    }
    setSelectedInvoices(
      invoices.filter(({ regular }) => !regular).map(({ _id }) => _id)
    );
  };

  const searchSuppliers = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/suppliers`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const onNewInvoiceConfirm = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const fetchAdminCollabs = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admin-collabs`,
      {
        headers: { Authorization: token },
        params: {
          adminId: profile
            ? profile.type === 'ADMIN_COLLAB'
              ? profile.adminId
              : profile._id
            : '',
        },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    setAdminCollabs(data);
  };

  return (
    <RoyaltiesPresentational
      data={invoices}
      dataExport={dataExport}
      profile={profile}
      profileScore={profileScore}
      query={query}
      total={total}
      onFilter={onFilter}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      searchSuppliers={searchSuppliers}
      onSelectAll={onSelectAll}
      onNewInvoiceConfirm={onNewInvoiceConfirm}
      fetchInvoicesExport={fetchInvoicesExport}
      isReady={isReady}
      setIsReady={setIsReady}
      adminCollabs={adminCollabs}
    />
  );
};

export default Royalties;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useLoading } from '../../../commons/Loading';
import { useAlert } from 'react-alert';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { getCondoReports } from '../../../lib/fetch/reports';
import FinancialReportsPresentational from './FinancialReportsPresentational';
import VariableRevenuesDetail from './VariableRevenues/VariableRevenuesDetail';
import VarRevInsDetail from './VariableRevenuesIns';
import VarRevEnergyDetail from './VarRevEnergy';
import VarRevRecurrentEnergyDetail from './VarRevRecurrentEnergy';

function CondoFinancialReports(props) {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const {
    _id: profileId,
    master: isProfileMaster,
    type: profileType = '',
  } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { id: condoId, data: condo, match = {} } = props;
  const [reports, setReports] = useState({});

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
    supplier: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    insuranceNumber: withDefault(StringParam, ''),
    insuranceAdmin: withDefault(StringParam, ''),
    insuranceSupplier: withDefault(StringParam, ''),
    subAdminsInvoicesNumber: withDefault(StringParam, ''),
    insuranceAccountabilityYear: NumberParam,
    insuranceAccountabilityMonth: NumberParam,
    subAdminsInvoicesAdmin: withDefault(StringParam, ''),
    subAdminsInvoicesSupplier: withDefault(StringParam, ''),
    subAdminsInvoicesAccountabilityYear: NumberParam,
    subAdminsInvoicesAccountabilityMonth: NumberParam,
    energyPod: withDefault(StringParam, ''),
    energyCondoVat: withDefault(StringParam, ''),
    energyAccountabilityYear: NumberParam,
    energyAccountabilityMonth: NumberParam,
    energyRecurrentPod: withDefault(StringParam, ''),
    energyRecurrentCondoVat: withDefault(StringParam, ''),
    energyRecurrentAccountabilityYear: NumberParam,
    energyRecurrentAccountabilityMonth: NumberParam,
  });

  const {
    supplier,
    number,
    year,
    accountabilityYear,
    accountabilityMonth,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    subAdminsInvoicesAdmin,
    subAdminsInvoicesNumber,
    subAdminsInvoicesSupplier,
    subAdminsInvoicesAccountabilityYear,
    subAdminsInvoicesAccountabilityMonth,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    energyRecurrentPod,
    energyRecurrentCondoVat,
    energyRecurrentAccountabilityYear,
    energyRecurrentAccountabilityMonth,
  } = query;

  function onFilter(queryDiff) {
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    if (
      (queryDiff.insuranceAccountabilityYear &&
        queryDiff.insuranceAccountabilityYear !==
          insuranceAccountabilityYear) ||
      queryDiff.insuranceAccountabilityYear === ''
    ) {
      queryDiff.insuranceAccountabilityMonth = '';
    }
    if (
      (queryDiff.subAdminsInvoicesAccountabilityYear &&
        queryDiff.subAdminsInvoicesAccountabilityYear !==
          subAdminsInvoicesAccountabilityYear) ||
      queryDiff.subAdminsInvoicesAccountabilityYear === ''
    ) {
      queryDiff.subAdminsInvoicesAccountabilityMonth = '';
    }

    if (
      (queryDiff.energyAccountabilityYear &&
        queryDiff.energyAccountabilityYear !== energyAccountabilityYear) ||
      queryDiff.energyAccountabilityYear === ''
    ) {
      queryDiff.energyAccountabilityMonth = '';
    }

    if (
      (queryDiff.energyRecurrentAccountabilityYear &&
        queryDiff.energyRecurrentAccountabilityYear !==
          energyRecurrentAccountabilityYear) ||
      queryDiff.energyRecurrentAccountabilityYear === ''
    ) {
      queryDiff.energyRecurrentAccountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  const fetchCondoReports = async () => {
    showLoading();
    const { error, data } = await getCondoReports({
      condoId,
      token,
      year,
      number,
      condoVatCode: condo.vatCode,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setReports(data);
    hideLoading();
  };

  useEffect(() => {
    if (condo.vatCode) {
      fetchCondoReports();
    }
  }, [year, number, condo]);

  return (
    <>
      <Switch>
        <Route
          exact
          path={match.url}
          component={(props) => (
            <FinancialReportsPresentational
              data={reports}
              profile={profile}
              onAddBonus={() => {}}
              onBonusDelete={() => {}}
              onBonusUpdate={() => {}}
              onFilter={onFilter}
              query={query}
              profileType={profileType}
              isProfileMaster={isProfileMaster}
              {...props}
            />
          )}
        />
        <Route
          path={`${match.url}/variable-revenues`}
          component={(props) => (
            <VariableRevenuesDetail
              profile={profile}
              condoId={condoId}
              condo={condo}
              {...props}
            />
          )}
        />
        <Route
          path={`${match.url}/variable-revenues-insurances`}
          component={(props) => (
            <VarRevInsDetail
              profile={profile}
              condoId={condoId}
              condo={condo}
              {...props}
            />
          )}
        />
        <Route
          path={`${match.url}/variable-revenues-energy`}
          component={(props) => (
            <VarRevEnergyDetail
              profile={profile}
              condoId={condoId}
              condo={condo}
              {...props}
            />
          )}
        />
        <Route
          path={`${match.url}/variable-revenues-recurrent-energy`}
          component={(props) => (
            <VarRevRecurrentEnergyDetail
              profile={profile}
              condoId={condoId}
              condo={condo}
              {...props}
            />
          )}
        />
      </Switch>
    </>
  );
}

export default withRouter(CondoFinancialReports);

import React, { useEffect, useState } from 'react';
import VarRevEnergyDetailPresentational from './VarRevEnergyDetailPresentational';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useLoading } from '../../../../commons/Loading';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import {
  getCondoReportsVariableRevenuesEnergy,
  getReportsVariableRevenuesEnergy,
} from '../../../../lib/fetch/reports';
import { useAuth } from '../../../../commons/Auth';

const VarRevEnergyDetail = (props) => {
  const { profile = {}, condoId, condo } = props;

  const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
  const { _id: profileId } = profile;
  const { showLoading, hideLoading } = useLoading();
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });

  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: withDefault(NumberParam, new Date().getFullYear()),
    energyPod: withDefault(StringParam, ''),
    energyCondoVat: withDefault(StringParam, ''),
    energyAccountabilityYear: NumberParam,
    energyAccountabilityMonth: NumberParam,
  });

  const {
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
  } = query;

  const fetchCondoReportsRevenuesEnergy = async () => {
    showLoading();
    const response = await getCondoReportsVariableRevenuesEnergy({
      limit,
      offset,
      search,
      year,
      energyPod,
      energyCondoVat,
      energyAccountabilityYear,
      energyAccountabilityMonth,
      condoId,
      condoVatCode: condo.vatCode,
      token,
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setReports(data);
    setTotal(data.categories?.variableRevenuesEnergy?.count);
  };

  useEffect(() => {
    if (condo._id) {
      fetchCondoReportsRevenuesEnergy();
    }
  }, [
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.energyAccountabilityYear &&
        queryDiff.energyAccountabilityYear !== energyAccountabilityYear) ||
      queryDiff.energyAccountabilityYear === ''
    ) {
      queryDiff.energyAccountabilityMonth = '';
    }

    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  return (
    <VarRevEnergyDetailPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      total={total}
      profileType={profile.type}
      {...props}
    />
  );
};

export default VarRevEnergyDetail;

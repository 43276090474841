import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import {
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
  H4Styled,
} from '../../styled-components';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import InvoiceTabel from './InvoiceTabel';
import ContractsTabel from './ContractsTabel';
import RecurrentContractsTabel from './RecurrentContractsTabel';
import classnames from 'classnames';
import { createBonus } from '../../lib/fetch/reports';
import moment from 'moment';

function ModalAllIafs({ data, year, fetchExtraInvoicesByMonth }) {
  const [t] = useTranslation('IAF_BY_MONTH');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token, profile = {} } = {}] = useAuth();

  const [modal, setModal] = useState(false);
  const [sentinel, setSentinel] = useState('invoices');

  const [dataForIafsInvoices, setDataForIafsInvoices] = useState([]);
  const [dataForIafsContracts, setDataForIafsContracts] = useState([]);
  const [
    dataForIafsRecurrentContracts,
    setDataForIafsRecurrentContracts,
  ] = useState([]);

  const [totIafs, setTotIafs] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totInvoices, setTotInvoices] = useState(0);
  const [totContracts, setTotContracts] = useState(0);
  const [totRecurrentContracts, setTotRecurrentContracts] = useState(0);

  const toggle = () => {
    setModal(!modal);
  };

  const confirmPreview = async (bonus) => {
    const { error } = await createBonus({
      token,
      bonus: { ...bonus, profileId: data.profileId },
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
  };

  const handleConfirm = async () => {
    try {
      showLoading();

      if (dataForIafsInvoices.length > 0) {
        await confirmPreview({
          iafType: 'INVOICES',
          amount: totInvoices,
          date: moment.utc().startOf('day').format(),
          accountability: year,
          invoices: dataForIafsInvoices.map(
            ({
              _id,
              accountability,
              adminShare,
              net,
              supplier,
              customer,
              date,
              number,
            }) => ({
              _id,
              accountability: accountability || undefined,
              admin: {
                _id: data.profileId,
                businessName: data.businessName,
              },
              earnedAdminShare: adminShare,
              adminShare,
              net,
              supplier,
              customer,
              date,
              number,
            })
          ),
        });
      }

      if (dataForIafsContracts.length > 0) {
        await confirmPreview({
          iafType: 'ENERGY',
          amount: totContracts,
          date: moment.utc().startOf('day').format(),
          accountability: year,
          contracts: dataForIafsContracts.map(
            ({ _id, admin, condo, adminShare, extraShare, date, number }) => ({
              _id,
              admin,
              condo,
              earnedAdminShare: adminShare,
              adminShare,
              net: extraShare,
              date,
              number,
            })
          ),
        });
      }

      if (dataForIafsRecurrentContracts.length > 0) {
        await confirmPreview({
          iafType: 'RECURRENT_ENERGY',
          amount: totRecurrentContracts,
          date: moment.utc().startOf('day').format(),
          accountability: year,
          recurrentContracts: dataForIafsRecurrentContracts.map(
            ({ _id, condo, adminShare, net, date, number }) => ({
              _id,
              admin: {
                _id: data.profileId,
                businessName: data.businessName,
              },
              condo,
              earnedAdminShare: adminShare,
              adminShare,
              net,
              date,
              number,
            })
          ),
        });
      }

      setDataForIafsInvoices([]);
      setDataForIafsContracts([]);
      setDataForIafsRecurrentContracts([]);
      toggle();
      await fetchExtraInvoicesByMonth(true);

      alert.success(t('COMMON:SUCCESS_UPDATE'));
    } catch (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (modal) {
      let invoices = [];
      let contracts = [];
      let recurrentContracts = [];

      for (const month of data.invoices) {
        if (month.invoices && month.invoices.length > 0) {
          invoices = [...invoices, ...month.invoices];
        }
      }

      for (const month of data.contracts) {
        if (month.contracts && month.contracts.length > 0) {
          contracts = [...contracts, ...month.contracts];
        }
      }

      for (const month of data.recurrentContracts) {
        if (month.recurrentContracts && month.recurrentContracts.length > 0) {
          recurrentContracts = [
            ...recurrentContracts,
            ...month.recurrentContracts,
          ];
        }
      }

      setTotalCount(
        invoices.length + contracts.length + recurrentContracts.length
      );
      setDataForIafsInvoices(invoices);
      setDataForIafsContracts(contracts);
      setDataForIafsRecurrentContracts(recurrentContracts);

      const sum = [...invoices, ...contracts, ...recurrentContracts].reduce(
        (acc, item) => acc + (item.adminShare || 0),
        0
      );
      const sumInvoices = invoices.reduce(
        (acc, item) => acc + (item.adminShare || 0),
        0
      );
      const sumContracts = contracts.reduce(
        (acc, item) => acc + (item.adminShare || 0),
        0
      );
      const sumRecurrentContracts = recurrentContracts.reduce(
        (acc, item) => acc + (item.adminShare || 0),
        0
      );

      setTotIafs(sum.toFixed(2));
      setTotInvoices(sumInvoices.toFixed(2));
      setTotContracts(sumContracts.toFixed(2));
      setTotRecurrentContracts(sumRecurrentContracts.toFixed(2));
    } else {
      setDataForIafsInvoices([]);
      setDataForIafsContracts([]);
      setDataForIafsRecurrentContracts([]);
      setTotIafs(0);
      setTotInvoices(0);
      setTotContracts(0);
      setTotRecurrentContracts(0);
      setTotalCount(0);
    }
  }, [modal]);

  return (
    <>
      <div className="flex-check">
        <BtnModalTable
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            toggle();
          }}
        >
          Emetti tutto
        </BtnModalTable>
      </div>

      <Modal isOpen={modal} className="iafs-modal">
        <ModalHeader toggle={toggle}>
          Anteprima Iafs
          <br />
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Totale da emettere: € {totIafs}
            </h5>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Elementi da emettere: {totalCount}
            </h5>
          </div>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: sentinel === 'invoices' })}
                onClick={() => setSentinel('invoices')}
                style={{ cursor: 'pointer' }}
              >
                Fatture
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: sentinel === 'contracts' })}
                onClick={() => setSentinel('contracts')}
                style={{ cursor: 'pointer' }}
              >
                Energia
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: sentinel === 'recurrentContracts',
                })}
                onClick={() => setSentinel('recurrentContracts')}
                style={{ cursor: 'pointer' }}
              >
                Energia Ricorrente
              </NavLink>
            </NavItem>
          </Nav>

          {sentinel === 'invoices' && dataForIafsInvoices?.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <InvoiceTabel
                data={dataForIafsInvoices}
                onFilter={() => {}}
                query={{
                  limit: dataForIafsInvoices?.length || 50,
                  offset: 0,
                }}
                total={dataForIafsInvoices?.length}
              />
            </div>
          )}

          {sentinel === 'invoices' && dataForIafsInvoices?.length === 0 && (
            <H4Styled style={{ textAlign: 'center', marginTop: '15px' }}>
              Non ci sono fatture su cui emettere iaf
            </H4Styled>
          )}

          {sentinel === 'contracts' && dataForIafsContracts?.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <ContractsTabel
                data={dataForIafsContracts}
                onFilter={() => {}}
                query={{
                  limit: dataForIafsContracts?.length || 50,
                  offset: 0,
                }}
                total={dataForIafsContracts?.length}
              />
            </div>
          )}

          {sentinel === 'contracts' && dataForIafsContracts?.length === 0 && (
            <H4Styled style={{ textAlign: 'center', marginTop: '15px' }}>
              Non ci sono contratti energia su cui emettere iaf
            </H4Styled>
          )}

          {sentinel === 'recurrentContracts' &&
            dataForIafsRecurrentContracts?.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                <RecurrentContractsTabel
                  data={dataForIafsRecurrentContracts}
                  onFilter={() => {}}
                  query={{
                    limit: dataForIafsRecurrentContracts?.length || 50,
                    offset: 0,
                  }}
                  total={dataForIafsRecurrentContracts?.length}
                />
              </div>
            )}

          {sentinel === 'recurrentContracts' &&
            dataForIafsRecurrentContracts?.length === 0 && (
              <H4Styled style={{ textAlign: 'center', marginTop: '15px' }}>
                Non ci sono contratti energia ricorrenti su cui emettere iaf
              </H4Styled>
            )}
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          <BtnOutlineGreen
            type="button"
            className="uppercase"
            onClick={handleConfirm}
          >
            Conferma Iaf
          </BtnOutlineGreen>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalAllIafs;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import { BtnOutlineGreen } from '../../styled-components';
import _ from 'lodash';
import { getValidationErrorMessage } from './helpers';
import ExportCSVRow from './ExportCSVRow';
// import ExportCSVRow from './ExportCSVRow';

function StudioInvoicesImportFeedbackModal({ toggle, isOpen, data = {} }) {
  const [t] = useTranslation('STUDIO_INVOICES');

  const {
    errors = {},
    insertedStudioInvoices,
    validationErrors: topValidationErrors,
    updatedStudioInvoices,
  } = data;
  let validationErrors = false;
  if (topValidationErrors && Array.isArray(topValidationErrors.message)) {
    validationErrors = topValidationErrors.message;
  }
  if (
    topValidationErrors &&
    topValidationErrors.message &&
    !Array.isArray(topValidationErrors.message)
  ) {
    validationErrors = [topValidationErrors.message];
  }

  const {
    clientsNotFound = [],
    adminsNotFound = [],
    nonCompliantCustomerAdmin = [],
  } = errors;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {validationErrors
          ? t('MALFORMED_FILE')
          : t('IMPORTED_STUDIO_INVOICES_FEEDBACK')}
      </ModalHeader>

      {!validationErrors && (
        <ModalBody>
          <Row>
            <Col sm="12">
              <h5>
                {t('INSERTED_STUDIO_INVOICES_NUMBER')}{' '}
                {insertedStudioInvoices?.length || 0}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <h5>
                {t('UPDATED_STUDIO_INVOICES_NUMBER')}{' '}
                {updatedStudioInvoices?.length || 0}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <h5>
                {t('NOT_INSERTED_STUDIO_INVOICES_NUMBER')}:{' '}
                {_.uniqWith(
                  [
                    ...clientsNotFound,
                    ...adminsNotFound,
                    ...nonCompliantCustomerAdmin,
                  ],
                  _.isEqual
                ).length || 0}
              </h5>
              <ul>
                <ExportCSVRow
                  data={clientsNotFound}
                  text={t('CLIENTS_NOT_FOUND')}
                />

                <ExportCSVRow
                  data={adminsNotFound}
                  text={t('ADMINS_NOT_FOUND')}
                />

                <ExportCSVRow
                  data={nonCompliantCustomerAdmin}
                  text={t('NOT_COMPLIANT_CUSTOMER_ADMIN')}
                />
              </ul>
            </Col>
          </Row>
        </ModalBody>
      )}
      {validationErrors && (
        <ModalBody>
          <Row>
            <Col sm="12">
              {validationErrors.map((v) => (
                <h5>{getValidationErrorMessage({ message: v })}</h5>
              ))}
            </Col>
          </Row>
        </ModalBody>
      )}
      <ModalFooter>
        <BtnOutlineGreen type="button" className="uppercase" onClick={toggle}>
          {t('COMMON:CLOSE')}
        </BtnOutlineGreen>
      </ModalFooter>
    </Modal>
  );
}

export default StudioInvoicesImportFeedbackModal;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  FormText,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
  ContFilterYear,
} from '../../styled-components';
import DateInput from '../../commons/DateInput';
import AutoSuggest from '../../commons/Autosuggest';
import './MyCondos.scss';
import {
  POSTAL_CODE_REGEX,
  PROVINCE_REGEX,
  FISCAL_CODE_REGEX,
} from '../../lib/regex';
import moment from 'moment';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';
import SameVatCondoConfirm from './SameVatCondoConfirm';
import Autosuggest from '../../commons/Autosuggest';
import { useAuth } from '../../commons/Auth';

const INITIAL_CONDO_STATE = {
  admin: {
    _id: '',
    businessName: '',
  },
  name: '',
  vatCode: '',
  street: '',
  postalCode: '',
  city: '',
  province: '',
  manufacturedUnitNumber: '',
  studioNumber: null,
  startDate: null,
  endDate: null,
  adminCollab: { _id: '', name: '' },
};

const ModalNewCondo = ({
  administrator,
  searchAdministrators,
  onConfirm,
  checkVatCode,
  fetchAdminCollabsForm,
  setSelectedAdminId,
}) => {
  const [t] = useTranslation('CONDOS');
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const isAdminUser = !!administrator;
  const alert = useAlert();
  const [{ profile } = {}] = useAuth();

  const INITIAL_ADMIN = isAdminUser
    ? {
        _id: administrator._id,
        businessName: administrator.businessName,
      }
    : { ...INITIAL_CONDO_STATE.admin };

  const [formValues, setFormValues] = useState({
    ...INITIAL_CONDO_STATE,
    admin: INITIAL_ADMIN,
  });

  const [formErrors, setFormErrors] = useState({
    admin: false,
    name: false,
    vatCode: false,
    street: false,
    postalCode: false,
    city: false,
    province: false,
    manufacturedUnitNumber: false,
    invalidManufacturedUnitNumber: false,
  });

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_CONDO_STATE,
      admin: INITIAL_ADMIN,
    });
  };

  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  function onInputChange({ target, uppercase }) {
    setFormValues((state) => ({
      ...state,
      [target.name]: uppercase ? target.value?.toUpperCase() : target.value,
    }));
  }

  const renderAdminSuggestion = ({ businessName = '' }) => businessName;
  const getAdminSuggestionValue = ({ businessName = '' }) => businessName;
  function onAdminSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
    }));
  }

  function onAdminCollabSuggestionSelect({ _id, name }) {
    setFormValues((state) => ({
      ...state,
      adminCollab: { _id, name },
    }));
  }
  const renderAdminCollabSuggestion = ({ name = '' }) => name;
  const getAdminCollabSuggestionValue = ({ name = '' }) => name;

  const onNewCondoConfirm = async () => {
    const {
      admin,
      name,
      vatCode,
      street,
      postalCode,
      city,
      province,
      startDate,
      endDate,
    } = formValues;

    const errors = {};

    if (!admin._id) errors.admin = true;
    if (!name) errors.name = true;
    if (!manufacturedUnitNumber) errors.manufacturedUnitNumber = true;
    if (manufacturedUnitNumber && manufacturedUnitNumber <= 0)
      errors.invalidManufacturedUnitNumber = true;
    if (!vatCode) errors.vatCode = true;
    if (vatCode && !FISCAL_CODE_REGEX.test(vatCode))
      errors.vatCodeFormat = true;
    if (!street) errors.street = true;
    if (!postalCode) errors.postalCode = true;
    if (postalCode && !POSTAL_CODE_REGEX.test(postalCode))
      errors.postalCodeFormat = true;
    if (!city) errors.city = true;
    if (!province) errors.province = true;
    if (province && !PROVINCE_REGEX.test(province))
      errors.provinceFormat = true;
    if (moment(startDate).isAfter(endDate)) errors.dates = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    try {
      const { isDuplicated } = await checkVatCode(formValues.vatCode);
      if (isDuplicated) toggleConfirmModal();
      else {
        onConfirm({
          ...formValues,
          admin: formValues.admin._id,
        });
        toggle();
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const handleFetchAdminCollabsForm = async () => {
    return fetchAdminCollabsForm(admin._id);
  };

  const {
    admin,
    name,
    vatCode,
    street,
    postalCode,
    city,
    province,
    manufacturedUnitNumber,
    studioNumber,
    adminCollab,
    startDate,
    endDate,
  } = formValues;

  return (
    <>
      <React.Fragment>
        <BtnRadiusRed onClick={toggle} type="button">
          <IconPlus />
        </BtnRadiusRed>
        <PNewItem
          onClick={toggle}
          style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
        >
          {t('NEW_CONDOS')}
        </PNewItem>

        <Modal
          isOpen={modal}
          className="new-condo-modal"
          onOpened={listenForEscapeEvent}
        >
          <ModalHeader toggle={toggle}>{t('NEW_CONDOS')}</ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onNewCondoConfirm(formValues);
            }}
          >
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Row>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('ROYALTY:AMMINISTRATOR')} *</Label>
                        {isAdminUser ? (
                          <Input
                            type="text"
                            name="admin"
                            value={admin.businessName}
                            disabled
                          />
                        ) : (
                          <AutoSuggest
                            placeholder="Cerca un amministratore..."
                            onFetch={searchAdministrators}
                            onSelect={onAdminSuggestionSelect}
                            renderSuggestion={renderAdminSuggestion}
                            getSuggestionValue={getAdminSuggestionValue}
                            value={admin.businessName}
                            onChange={(businessName) =>
                              setFormValues((state) => ({
                                ...state,
                                admin: { ...admin, businessName },
                              }))
                            }
                          />
                        )}
                        {formErrors.admin && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('NAME')} *</Label>
                        <Input
                          type="text"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                        />
                        {formErrors.name && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('CONDOS:VAT_CODE')} *</Label>
                        <Input
                          type="text"
                          name="vatCode"
                          value={vatCode}
                          onChange={onInputChange}
                        />
                        {formErrors.vatCode && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.vatCodeFormat && (
                          <FormText className="error-message">
                            {t('COMMON:INVALID_FISCAL_CODE_TAXCODE')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="8" md="4">
                      <FormGroup>
                        <Label>{t('STREET')} *</Label>
                        <Input
                          type="text"
                          name="street"
                          value={street}
                          onChange={onInputChange}
                        />
                        {formErrors.street && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4" md="2">
                      <FormGroup>
                        <Label>{t('POSTAL_CODE')} *</Label>
                        <Input
                          type="text"
                          name="postalCode"
                          value={postalCode}
                          onChange={onInputChange}
                        />
                        {formErrors.postalCode && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.postalCodeFormat && (
                          <FormText className="error-message">
                            {t('CONDOS:POSTAL_CODE_FORMAT_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="8" md="4">
                      <FormGroup>
                        <Label>{t('CITY')} *</Label>
                        <Input
                          type="text"
                          name="city"
                          value={city}
                          onChange={onInputChange}
                        />
                        {formErrors.city && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4" md="2">
                      <FormGroup>
                        <Label>{t('PROVINCE')} *</Label>
                        <Input
                          type="text"
                          name="province"
                          value={province}
                          onChange={({ target }) =>
                            onInputChange({ target, uppercase: true })
                          }
                          placeholder="es. MI"
                        />
                        {formErrors.province && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.provinceFormat && (
                          <FormText className="error-message">
                            {t('CONDOS:PROVINCE_FORMAT_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label>{t('MAN_UNIT_NUMBER')} *</Label>
                        <Input
                          type="number"
                          name="manufacturedUnitNumber"
                          value={manufacturedUnitNumber}
                          onChange={onInputChange}
                        />
                        {formErrors.manufacturedUnitNumber && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.invalidManufacturedUnitNumber && (
                          <FormText className="error-message">
                            {t('CONDOS:INVALID_MAN_UNIT_NUMBER')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label>{t('STUDIO_NUMBER')}</Label>
                        <Input
                          type="number"
                          name="studioNumber"
                          value={studioNumber}
                          onChange={onInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="4">
                      <FormGroup>
                        <Label>{t('ADMIN_COLLAB')}</Label>
                        <Autosuggest
                          placeholder="Cerca un collaboratore..."
                          onFetch={handleFetchAdminCollabsForm}
                          onSelect={onAdminCollabSuggestionSelect}
                          renderSuggestion={renderAdminCollabSuggestion}
                          getSuggestionValue={getAdminCollabSuggestionValue}
                          value={adminCollab.name}
                          disabled={admin._id === ''}
                          onChange={(name) => {
                            if (name.length > 0) {
                              setFormValues((state) => ({
                                ...state,
                                adminCollab: { ...adminCollab, name },
                              }));
                            } else {
                              setFormValues((state) => ({
                                ...state,
                                adminCollab: { _id: '', name: '' },
                              }));
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <p className="text-bold mb-2">
                        {t('MANAGEMENT_PERIOD')}:
                      </p>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('START_DATE')}</Label>
                        <DateInput
                          name="startDate"
                          value={startDate}
                          onChange={(startDate) =>
                            setFormValues((state) => ({ ...state, startDate }))
                          }
                        />
                        {formErrors.dates && (
                          <FormText className="error-message">
                            {t('DATES_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('END_DATE')}</Label>
                        <DateInput
                          name="endDate"
                          value={endDate}
                          onChange={(endDate) =>
                            setFormValues((state) => ({ ...state, endDate }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggle}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>

      <SameVatCondoConfirm
        isOpen={confirmModal}
        toggle={toggleConfirmModal}
        onConfirm={() => {
          onConfirm({
            ...formValues,
            admin: formValues.admin._id,
          });
          toggleConfirmModal();
          toggle();
        }}
      />
    </>
  );
};

export default ModalNewCondo;

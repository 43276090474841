import React, { useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { BtnModalTable } from '../../../styled-components';
import { useAlert } from 'react-alert';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';

function ActionsField(props) {
  const { data, onStatusChanged } = props;

  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('SUBSCRIPTIONS');

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });

  return (
    <>
      <div className="flex-check">
        <BtnModalTable
          type="button"
          onClick={(event) => {
            setModalState({
              isOpen: true,
              selectedId: data.pinvInvoice[0]._id,
            });
          }}
          disabled={!data?.pinvInvoice?.length > 0}
          className={
            !data?.pinvInvoice?.length > 0 ? 'btn-disabled' : 'btn-active'
          }
        >
          {data?.pinvInvoice[0]?.invoicePinv?.status === 'to_deliver'
            ? t('GOTO_INVOICE_DRAFT_SUBSCRIPTION')
            : t('GOTO_INVOICE_SUBSCRIPTION')}
        </BtnModalTable>
      </div>

      <ModalInvoiceDetail
        modalState={modalState}
        setModalState={setModalState}
        onStatusChanged={onStatusChanged}
        disabledPinv={true}
      />
    </>
  );
}

export default ActionsField;

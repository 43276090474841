import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconCalendar } from '../../images/icn-calendar.svg';
import variables from '../../variables';
import {
  ContFilterYear,
  ContFlexBetween,
  ContPaginationPage,
  H4Styled,
} from '../../styled-components';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Footer from '../../commons/Footer';
import ExtraInvoicesByMonthTable from './Table';

function ExtraInvoiceByMonthPresentational({
  data = [],
  total,
  query = {},
  onFilter,
  fetchExtraInvoicesByMonth,
}) {
  const [t] = useTranslation();
  const [{ profile = {} } = {}] = useAuth();

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:IAF_BY_MONTH')}
            icon={<IconCalendar fill={variables.white} />}
          />
        </section>

        <section>
          <ContFlexBetween>
            <ContFilterYear>
              <Row>
                <Col xs="6">
                  <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <CustomInput
                      type="select"
                      id="selectYear"
                      name="year"
                      value={query.year}
                      onChange={({ target: { value: year } }) =>
                        onFilter({ year })
                      }
                    >
                      {generateYearOptions()}
                    </CustomInput>
                  </FormGroup>
                </Col>
              </Row>
            </ContFilterYear>
          </ContFlexBetween>

          <br />

          <ExtraInvoicesByMonthTable
            data={data}
            total={total}
            query={query}
            onFilter={onFilter}
            fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default ExtraInvoiceByMonthPresentational;

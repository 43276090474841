import React from 'react';
import { DataTable, DateTimeField } from '../../../commons/DataTable';
import { ContPaginationPage } from '../../../styled-components';
import { CustomInput, FormGroup } from 'reactstrap';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import SubscriptionBadgeField from '../../../commons/DataTable/SubscriptionBadgeField';
import SubscriptionCostField from '../../../commons/DataTable/SubscriptionCostField';
import { useTranslation } from 'react-i18next';
import ActionsField from './ActionsField';

const SubscriptionTable = ({
  data,
  total,
  query,
  offset = 0,
  limit = 50,
  onFilter,
  onStatusChanged,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <DataTable
        data={data}
        total={total}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        emptyText={t('COMMON:NO_DATA')}
      >
        <DateTimeField
          title="Inizio abbonamento"
          source="startDate"
          format="DD/MM/YYYY"
        />
        <DateTimeField
          title="Fine abbonamento"
          source="expireDate"
          format="DD/MM/YYYY"
        />
        <SubscriptionCostField title="Costo" source="cost" />
        <SubscriptionBadgeField title="Stato" />
        <ActionsField title="Azioni" onStatusChanged={onStatusChanged} />
      </DataTable>

      <ContPaginationPage>
        <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

        <FormGroup>
          <CustomInput
            type="select"
            className="pagination-select"
            id="perPage"
            name="perPage"
            value={query.limit}
            onChange={({ target: { value: limit } }) => {
              onFilter({ limit });
            }}
          >
            {generatePaginationOptions()}
          </CustomInput>
        </FormGroup>
      </ContPaginationPage>
    </>
  );
};

export default SubscriptionTable;

import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import { Provider as AlertProvider, positions } from 'react-alert';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/it';
import {
  AuthProvider,
  AdminRoute,
  PrivateRoute,
  PublicRoute,
  RoutesWrapper,
} from './commons/Auth';
import AlertTemplate from './commons/AlertTemplate';
import { LoadingProvider } from './commons/Loading';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Signup from './pages/Signup';
import Verification from './pages/Verification';
import Dashboard from './pages/Dashboard';
import Profiles from './pages/Profiles';
import ProfileDetail from './pages/ProfileDetail';
import Info from './pages/Info';
import PageNotFound from './pages/PageNotFound';
import translations from './translations';
import './App.scss';
import DashboardDetail from './pages/DashboardDetail';
import DashboardDetailCompensi from './pages/DashboardDetailCompensi';
import MyProfile from './pages/MyProfile';
import MyFinancialReports from './pages/MyFinancialReports';
import MyAffiliations from './pages/MyAffiliations';
import MyInvoices from './pages/MyInvoices';
import MyRoyalties from './pages/MyRoyalties';
import MyInsurances from './pages/MyInsurances';
import DashboardListMasters from './pages/DashboardListMasters';
import DashboardDetailAssicurazioni from './pages/DashboardDetailAssicurazioni';
import Logs from './pages/Logs';
import InvoicesByMonth from './pages/InvoicesByMonth';
import Insurances from './pages/Insurances';
import Network from './pages/Network';
import ReportProfiles from './pages/ReportProfiles';
import MyStudioInvoices from './pages/MyStudioInvoices';
import Condos from './pages/Condos';
import EonCondos from './pages/EonCondos';
import SailInvoices from './pages/SailInvocies';
import DashboardDetailCompensiStudio from './pages/DashboardDetailCompensiStudio';
import MyEmittedIAF from './pages/MyEmittedIAF';
import MyReceivedIAF from './pages/MyReceivedIAF';
import Contracts from './pages/Contracts';
import ContractsResume from './pages/ContractsResume';
import ContractsRecurrencyResume from './pages/ContractsRecurrencyResume';
import MyReceivedExtraInvoice from './pages/MyReceivedExtraInvoice';
import CustomersExtra from './pages/CustomerExtra';
import CustomerExtraMasters from './pages/CustomerExtraMasters';
import Clients from './pages/Clients';
import Albo from './pages/Albo';
import MyNetworkFinancialReports from './pages/MyNetworkFinancialReports';
import CreditCalculation from './pages/CreditCalculation';
import PartnerCondos from './pages/PartnerCondos';
import OverdueInvoices from './pages/OverdueInvoices';
import MyCollaborators from './pages/MyCollaborators';
import MyCollaboratorsDetail from './pages/MyCollaboratorsDetail';
import Activities from './pages/Activities';
import ActivitiesDetail from './pages/ActivitiesDetail';
import Agreements from './pages/Agreements';
import AgreementsDetail from './pages/AgreementsDetail';
import AgreementsEditor from './pages/AgreementsEditor';
import AgreementsModeling from './pages/AgreementsModeling';
import Notifications from './pages/Notifications';
import IafsByMonth from './pages/IafsByMonth';

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

moment.locale('it');

export const FINANCIAL_REPORTS_ALLOWED_TYPES = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'ADMIN_AGENT',
  'AGENCY',
]);

export const AFFILIATIONS_ALLOWED_TYPES = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'ADMIN_AGENT',
  'SUPPLIER',
  'SUPPLIER_INS',
  'SUPPLIER_INS',
  'AGENCY',
]);

export const INVOICES_ALLOWED_TYPES = Object.freeze(['SUPPLIER']);

export const ROYALTIES_ALLOWED_TYPES = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'ADMIN_AGENT',
  'AGENCY',
]);

export const AGENT_TYPES = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'AGENCY',
]);

export const PROFILE_DOCUMENTS_TYPES = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'SUPPLIER',
  'ADMIN_COLLAB',
]);

export const ADMIN_TYPES = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
]);

export const DELETE_INVOICES_TYPE = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'SUPPLIER',
]);

export const ENERGY_USERS = Object.freeze([
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
  'EON_ENERGY',
  'AGENCY',
]);

export const CUSTOMERS_ALLOWED_TYPES = Object.freeze([
  'SUPPLIER',
  'EON_ENERGY',
  'SUPPLIER_INS',
]);

export const CUSTOMERS_CONDOS_ALLOWED_TYPES = Object.freeze([
  'SUPPLIER',
  'SUPPLIER_INS',
]);

export const INSURANCES_ALLOWED_TYPES = Object.freeze(['SUPPLIER_INS']);

export const AGENCY_ALLOWED_TYPES = Object.freeze(['AGENCY']);

export const USER_ADMIN_TYPES = Object.freeze({
  SUPER_ADMIN: 'SUPER_ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  SUPERVISOR_USER: 'SUPERVISOR_USER',
});

export const CERTIFIER_TYPE = Object.freeze({ CERTIFIER: 'CERTIFIER' });

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          timeout={5000}
        >
          <CookiesProvider>
            <AuthProvider>
              <LoadingProvider>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <RoutesWrapper
                      adminRedirect="/dashboard"
                      publicRedirect="/"
                      privateRedirect="/my-profile"
                    >
                      <PublicRoute exact path="/" component={Login} />
                      <PublicRoute path="/signup" component={Signup} />
                      <PublicRoute path="/forgot" component={Forgot} />
                      <PublicRoute path="/reset" component={Reset} />
                      <PublicRoute
                        path="/verification"
                        component={Verification}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                      <AdminRoute exact path="/condos" component={Condos} />
                      <AdminRoute
                        exact
                        path="/dashboard/compensi"
                        component={DashboardDetailCompensi}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/compensi-studio"
                        component={DashboardDetailCompensiStudio}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/masters"
                        component={DashboardListMasters}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/assicurazioni"
                        component={DashboardDetailAssicurazioni}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/:clientType"
                        component={DashboardDetail}
                      />

                      <AdminRoute exact path="/profiles" component={Profiles} />
                      <AdminRoute
                        path="/profiles/:id"
                        component={ProfileDetail}
                      />
                      <PrivateRoute
                        path="/my-collaborators"
                        component={MyCollaborators}
                        allow={ADMIN_TYPES}
                      />
                      <AdminRoute path="/insurances" component={Insurances} />
                      <AdminRoute
                        path="/by-month"
                        component={InvoicesByMonth}
                      />
                      <AdminRoute
                        path="/iafs-by-month"
                        component={IafsByMonth}
                      />
                      {/*<AdminRoute*/}
                      {/*  exact*/}
                      {/*  path="/report-profiles"*/}
                      {/*  component={ReportProfiles}*/}
                      {/*/>*/}
                      <AdminRoute path="/logs" component={Logs} />
                      <AdminRoute
                        exact
                        path="/customer-extra-masters"
                        component={CustomerExtraMasters}
                      />
                      <PrivateRoute path="/my-profile" component={MyProfile} />
                      <PrivateRoute path="/my-condos" component={Condos} />
                      <PrivateRoute
                        path="/partner-condos"
                        component={PartnerCondos}
                        allow={CUSTOMERS_ALLOWED_TYPES}
                      />
                      <PrivateRoute path="/contracts" component={Contracts} />
                      <PrivateRoute
                        path="/my-eon-condos"
                        component={EonCondos}
                      />
                      <PrivateRoute
                        path="/contracts-resume"
                        component={ContractsResume}
                      />
                      <PrivateRoute
                        path="/contracts-recurrency-resume"
                        component={ContractsRecurrencyResume}
                      />
                      <PrivateRoute
                        path="/my-financial-reports"
                        component={MyFinancialReports}
                        allow={FINANCIAL_REPORTS_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-network-financial-reports"
                        component={MyNetworkFinancialReports}
                        allowAttr="commercial"
                      />
                      <PrivateRoute
                        path="/my-affiliations"
                        component={MyAffiliations}
                        allow={AFFILIATIONS_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/network"
                        component={Network}
                        allowAttr="commercial"
                      />
                      <PrivateRoute
                        path="/my-invoices"
                        component={MyInvoices}
                        allow={INVOICES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-by-month"
                        component={InvoicesByMonth}
                        allow={INVOICES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-royalties"
                        component={MyRoyalties}
                        allow={[...ROYALTIES_ALLOWED_TYPES, 'ADMIN_COLLAB']}
                      />
                      <PrivateRoute
                        path="/my-studio-invoices"
                        component={MyStudioInvoices}
                        allow={ROYALTIES_ALLOWED_TYPES}
                      />
                      <AdminRoute
                        path="/my-emitted-iaf"
                        component={MyEmittedIAF}
                      />
                      <PrivateRoute
                        path="/my-received-extra-invoice"
                        component={MyReceivedExtraInvoice}
                      />
                      <PrivateRoute
                        path="/my-received-iaf"
                        component={MyReceivedIAF}
                        allow={[...ROYALTIES_ALLOWED_TYPES, 'COMMERCIAL']}
                      />
                      <AdminRoute
                        path="/sail-invoices"
                        component={SailInvoices}
                      />
                      <AdminRoute
                        path="/overdue-invoices"
                        component={OverdueInvoices}
                      />
                      <PrivateRoute
                        path="/my-insurance-royalties"
                        component={Insurances}
                        allow={[...ROYALTIES_ALLOWED_TYPES, 'ADMIN_COLLAB']}
                      />
                      <PrivateRoute
                        path="/my-insurances"
                        component={MyInsurances}
                        allow={INSURANCES_ALLOWED_TYPES}
                      />

                      <PrivateRoute
                        path="/customer-extra"
                        component={CustomersExtra}
                        allow={[
                          'BUILDING_ADMIN_1',
                          'BUILDING_ADMIN_2',
                          'BUILDING_ADMIN_3',
                          'BUILDING_ADMIN_4',
                          'BUILDING_ADMIN_5',
                          'AGENCY',
                          'ADMIN_COLLAB',
                        ]}
                      />

                      <PrivateRoute
                        path="/my-customers"
                        component={Clients}
                        allow={CUSTOMERS_ALLOWED_TYPES}
                      />

                      <PrivateRoute
                        path="/albo"
                        component={Albo}
                        allow={[
                          ...ENERGY_USERS,
                          ...CUSTOMERS_ALLOWED_TYPES,
                          'ADMIN_COLLAB',
                          'COMMERCIAL',
                        ]}
                      />

                      {/* Area attivita */}
                      {process.env.REACT_APP_ID !== 'franchising-extra' ? (
                        <PrivateRoute
                          path="/activities"
                          component={Activities}
                          allow={[...ADMIN_TYPES]}
                        />
                      ) : (
                        <></>
                      )}
                      {process.env.REACT_APP_ID !== 'franchising-extra' ? (
                        <PrivateRoute
                          path="/activities-detail/:id"
                          component={ActivitiesDetail}
                          allow={[...ADMIN_TYPES]}
                        />
                      ) : (
                        <></>
                      )}

                      {/* Area contratti */}
                      {process.env.REACT_APP_ID !== 'franchising-extra' ? (
                        <PrivateRoute
                          path="/agreements"
                          component={Agreements}
                          allow={[...ADMIN_TYPES, 'SUPPLIER']}
                        />
                      ) : (
                        <></>
                      )}

                      {process.env.REACT_APP_ID !== 'franchising-extra' ? (
                        <PrivateRoute
                          path="/agreements-details/:id"
                          component={AgreementsDetail}
                          allow={[...ADMIN_TYPES, 'SUPPLIER']}
                        />
                      ) : (
                        <></>
                      )}

                      {process.env.REACT_APP_ID !== 'franchising-extra' ? (
                        <PrivateRoute
                          path="/agreements-modeling/:id"
                          component={AgreementsModeling}
                          allow={[...ADMIN_TYPES, 'SUPPLIER']}
                        />
                      ) : (
                        <></>
                      )}

                      {process.env.REACT_APP_ID !== 'franchising-extra' ? (
                        <PrivateRoute
                          path="/agreements-editor/:id"
                          component={AgreementsEditor}
                          allow={[...ADMIN_TYPES, 'SUPPLIER']}
                        />
                      ) : (
                        <></>
                      )}

                      <PrivateRoute
                        path="/credit-calculation"
                        component={CreditCalculation}
                        allow={ADMIN_TYPES}
                      />

                      <PrivateRoute
                        path="/notifications"
                        component={Notifications}
                      />

                      <PrivateRoute path="/info" component={Info} />
                      <Route component={PageNotFound} />
                    </RoutesWrapper>
                  </QueryParamProvider>
                </Router>
              </LoadingProvider>
            </AuthProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;

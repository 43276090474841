import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { H5Styled } from '../../../styled-components';
import CondoBalancesTable from './CondoBalancesTable';

const CondoBalancesPresentational = ({
  data,
  total,
  profile,
  condo,
  onFilter,
}) => {
  const [t] = useTranslation('CONDO_AGREEMENTS');

  return (
    <>
      <section style={{ marginTop: '10px' }}>
        <CondoBalancesTable data={data} total={total} profile={profile} />
      </section>
    </>
  );
};

export default CondoBalancesPresentational;

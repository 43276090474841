import React, { useState, useEffect } from 'react';
import { useLoading } from '../Loading';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../Auth';
import { changeStatus, findOne } from '../../lib/fetch/sailInvoices';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  UncontrolledCollapse,
} from 'reactstrap';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  Field,
  PercentageField,
} from '../DataTable';
import {
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
} from '../../styled-components';
import { formatDate, formatAddress } from '../../lib/helpers/formatters';
import TotalTr from './TotalTr';
import { applyPercentage } from '../../lib/helpers/financialMath';

function ModalInvoiceDetail({
  modalState,
  setModalState,
  onStatusChanged,
  disabledPinv = false,
}) {
  const { showLoading, hideLoading } = useLoading();
  const { isOpen, selectedId } = modalState;
  const [t] = useTranslation('SAIL_INVOICES');
  const alert = useAlert();
  const [{ token } = {}] = useAuth();
  const [invoice, setInvoice] = useState({});
  const [totals, setTotals] = useState({});

  const toggle = () => {
    setModalState({ isOpen: !isOpen, selectedId: null });
  };

  const findOneInvoice = async (selectedId) => {
    showLoading();
    const { error, data } = await findOne({
      token,
      id: selectedId,
    });

    if (error) {
      toggle();
      alert.error(t('COMMON:GENERIC_ERROR'));
      hideLoading();
      return;
    }

    setInvoice({
      ...data,
      item: data.item.map((el) => ({
        ...el,
        product: {
          ...el.product,
          totalVat: applyPercentage(
            el.product.subtotal,
            el.product.pricing.tax.value
          ),
          total:
            el.product.subtotal +
            applyPercentage(el.product.subtotal, el.product.pricing.tax.value),
        },
      })),
    });
    hideLoading();
  };

  const getTotals = ({ item }) => {
    const subTotals = item?.reduce(
      (acc, curr) => acc + curr.product.subtotal,
      0
    );
    const vatTotals = item?.reduce(
      (acc, curr) => acc + curr.product.totalVat,
      0
    );
    const amountTotals = item?.reduce(
      (acc, curr) => acc + curr.product.total,
      0
    );

    return {
      subTotals,
      vatTotals,
      amountTotals,
    };
  };

  useEffect(() => {
    setTotals(getTotals(invoice));
  }, [invoice]);

  const changeStatusInvoice = async (selectedId, status) => {
    showLoading();
    const { error, data } = await changeStatus({
      token,
      data: {
        id: selectedId,
        status,
      },
    });

    if (error) {
      toggle();
      alert.error(t('COMMON:GENERIC_ERROR'));
      hideLoading();
      return;
    }

    // setInvoice(data);
    toggle();
    hideLoading();
    onStatusChanged();
  };

  useEffect(() => {
    if (!!selectedId) setInvoice(findOneInvoice(selectedId));
  }, [selectedId]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('DETAIL')}</ModalHeader>

      <ModalBody>
        <Row>
          <Col sm="12">
            <h5>{t('GENERAL_INFO')}</h5>
          </Col>
          <Col sm="12" md="4">
            <h6>
              <strong>{t('DOC_TYPE')}</strong>
            </h6>
            {invoice.docType}
          </Col>
          <Col sm="12" md="4">
            <h6>
              <strong>{t('NUMBER')}</strong>
            </h6>
            {invoice.fullnumber}
          </Col>
          <Col sm="12" md="4">
            <h6>
              <strong>{t('DATE')}</strong>
            </h6>
            {formatDate(invoice.date)}
          </Col>
        </Row>
        <hr />
        <div className="p-3 bg-warning text-dark">
          <Row>
            <Col sm="6" md="6">
              <strong>{t('STATUS')}</strong>
            </Col>
            <Col sm="6" md="6">
              {invoice.status === 'to_deliver'
                ? invoice.einvoice?.status
                  ? invoice.einvoice?.status
                  : 'Bozza'
                : invoice.einvoice?.status
                ? invoice.einvoice?.status
                : 'Inviata'}
              <BtnModalTable
                type="button"
                className="ml-2 uppercase"
                id="toggler"
              >
                i
              </BtnModalTable>
            </Col>
          </Row>
        </div>
        <div>
          <UncontrolledCollapse toggler="#toggler">
            <DataTable
              data={invoice.einvoice?.notifications}
              emptyText={t('NO_DATA')}
              className="mt-2 mb-2"
            >
              <DateTimeField
                title="Data"
                source="messages[0].date_available"
                className="text-left"
                format="DD/MM/YYYY"
              />
              <Field
                title="Messaggio"
                source="messages[0].description"
                className="text-left"
              />
            </DataTable>
          </UncontrolledCollapse>
        </div>
        <hr />
        <Row>
          <Col sm="12">
            <h5>{t('CONTACTS')}</h5>
          </Col>
          <Col sm="12" md="6">
            <h6>
              <strong>{t('FROM')}</strong>
            </h6>
            {invoice.company_info?.company}
            <br />
            {formatAddress(invoice.company_info?.contact?.address)}
            <br />
            {`C.F: ${invoice.company_info?.codice_fiscale} | P.IVA. ${invoice.company_info?.vat}`}
          </Col>
          <Col sm="12" md="6">
            <h6>
              <strong>{t('TO')}</strong>
            </h6>
            {invoice.customer_legal_info?.company_name}
            <br />
            {formatAddress(invoice.customer_legal_info?.address)}
            <br />
            {`C.F: ${invoice.customer_legal_info?.codice_fiscale} | P.IVA. ${invoice.customer_legal_info?.vat}`}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm="12">
            <DataTable
              history
              data={invoice.item}
              loadingText={t('LOADING')}
              emptyText={t('NO_DATA')}
              bottomRow={<TotalTr totals={totals} />}
            >
              <Field
                title="Nome"
                source="product.title"
                className="text-left"
              />
              <CurrencyField
                title="Imponibile"
                source="product.subtotal"
                className="text-right"
              />
              <PercentageField
                title="IVA"
                source="product.pricing.tax.value"
                className="text-right"
              />
              <CurrencyField
                title="Importo IVA"
                source="product.totalVat"
                className="text-right"
              />
              <CurrencyField
                title="Importo"
                source="product.total"
                className="text-right"
              />
            </DataTable>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {invoice.status === 'to_deliver' && !disabledPinv && (
          <BtnOutlineRed
            type="button"
            className="uppercase"
            onClick={() => changeStatusInvoice(selectedId, 'deliver')}
          >
            {t('COMMON:DELIVER_INVOICE')}
          </BtnOutlineRed>
        )}
        <BtnOutlineGreen
          type="button"
          className="uppercase"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_PINV_BASE_PATH}/app/invoices/${invoice._id}`,
              '_blank'
            );
          }}
        >
          {t('VIEW_IN_PINV')}
        </BtnOutlineGreen>
        <BtnOutlineGreen type="button" className="uppercase" onClick={toggle}>
          {t('COMMON:CLOSE')}
        </BtnOutlineGreen>
      </ModalFooter>
    </Modal>
  );
}

export default ModalInvoiceDetail;

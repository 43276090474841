import React from 'react';

function AdminBalancesField({ data, maxLength }) {
  if (!data.admins || data.admins.length === 0) return <>-</>;

  const adminNames = data.admins
    .map((admin) => admin.profile?.businessName)
    .join(' - ');

  return <>{adminNames}</>;
}

export default AdminBalancesField;

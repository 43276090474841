import axios from 'axios';

export const getReports = async (props) => {
  const { year, number, token, profileId } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        number,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getCondoReports = async (props) => {
  const { year, number, token, condoId, condoVatCode } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/condo/${condoId}`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        number,
        condoVatCode,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenues = async (props) => {
  const {
    limit,
    offset,
    token,
    profileId,
    year,
    accountabilityYear,
    accountabilityMonth,
    supplier,
    number,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}/variableRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        accountabilityYear,
        accountabilityMonth,
        supplier,
        number,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getCondoReportsVariableRevenues = async (props) => {
  const {
    limit,
    offset,
    token,
    condoId,
    condoVatCode,
    year,
    accountabilityYear,
    accountabilityMonth,
    supplier,
    number,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/condo/${condoId}/variableRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        accountabilityYear,
        accountabilityMonth,
        supplier,
        number,
        condoVatCode,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesNetwork = async (props) => {
  const {
    limit,
    offset,
    token,
    profileId,
    year,
    accountabilityYear,
    accountabilityMonth,
    supplier,
    adminQuery,
    number,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/network/report/${profileId}/variableRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        accountabilityYear,
        accountabilityMonth,
        supplier,
        adminQuery,
        number,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesAdmin = async (props) => {
  const {
    limit,
    offset,
    year,
    search,
    subAdminsInvoicesSupplier,
    subAdminsInvoicesNumber,
    subAdminsInvoicesAdmin,
    subAdminsInvoicesAccountabilityYear,
    subAdminsInvoicesAccountabilityMonth,
    token,
    profileId,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}/variableRevenuesSubAdmin`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        search,
        subAdminsInvoicesSupplier,
        subAdminsInvoicesNumber,
        subAdminsInvoicesAdmin,
        subAdminsInvoicesAccountabilityYear,
        subAdminsInvoicesAccountabilityMonth,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesInsurances = async (props) => {
  const {
    limit,
    offset,
    year,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    search,
    token,
    profileId,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}/insuranceRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        insuranceNumber,
        insuranceAdmin,
        insuranceSupplier,
        insuranceAccountabilityYear,
        insuranceAccountabilityMonth,
        search,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getCondoReportsVariableRevenuesInsurances = async (props) => {
  const {
    limit,
    offset,
    year,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    search,
    token,
    condoId,
    condoVatCode,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/condo/${condoId}/insuranceRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        insuranceNumber,
        insuranceAdmin,
        insuranceSupplier,
        insuranceAccountabilityYear,
        insuranceAccountabilityMonth,
        search,
        condoVatCode,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesInsurancesNetwork = async (props) => {
  const {
    limit,
    offset,
    year,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    search,
    token,
    profileId,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/network/report/${profileId}/insuranceRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        insuranceNumber,
        insuranceAdmin,
        insuranceSupplier,
        insuranceAccountabilityYear,
        insuranceAccountabilityMonth,
        search,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesEnergy = async (props) => {
  const {
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    customerType,
    profileId,
    token,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}/energyFeeRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        search,
        year,
        energyPod,
        energyCondoVat,
        energyAccountabilityYear,
        energyAccountabilityMonth,
        customerType,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getCondoReportsVariableRevenuesEnergy = async (props) => {
  const {
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    condoId,
    condoVatCode,
    token,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/condo/${condoId}/energyFeeRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        search,
        year,
        energyPod,
        energyCondoVat,
        energyAccountabilityYear,
        energyAccountabilityMonth,
        condoVatCode,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesEnergyNetwork = async (props) => {
  const {
    limit,
    offset,
    search,
    year,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    profileId,
    token,
    customerType,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/network/report/${profileId}/energyFeeRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        search,
        year,
        customerType,
        energyPod,
        energyCondoVat,
        energyAccountabilityYear,
        energyAccountabilityMonth,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getReportsVariableRevenuesRecurrentEnergy = async (props) => {
  const {
    limit,
    offset,
    year,
    search,
    energyRecurrentPod,
    energyRecurrentCondoVat,
    energyRecurrentAccountabilityYear,
    energyRecurrentAccountabilityMonth,
    token,
    customerType,
    profileId,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}/recurrentEnergyFeeRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        search,
        customerType,
        energyRecurrentPod,
        energyRecurrentCondoVat,
        energyRecurrentAccountabilityYear,
        energyRecurrentAccountabilityMonth,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getCondoReportsVariableRevenuesRecurrentEnergy = async (props) => {
  const {
    limit,
    offset,
    year,
    search,
    energyRecurrentPod,
    energyRecurrentCondoVat,
    energyRecurrentAccountabilityYear,
    energyRecurrentAccountabilityMonth,
    token,
    condoId,
    condoVatCode,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/condo/${condoId}/recurrentEnergyFeeRevenues`,
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        offset,
        year,
        search,
        energyRecurrentPod,
        energyRecurrentCondoVat,
        energyRecurrentAccountabilityYear,
        energyRecurrentAccountabilityMonth,
        condoVatCode,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const inviteToInvoice = async ({ token, data }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/invite-to-invoice`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const createBonus = async ({ token, bonus }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/bonuses`,
      data: bonus,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const createBonusCommercial = async ({ token, bonus }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/network/bonuses`,
      data: bonus,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const deleteBonus = async ({ token, _id }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${_id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const updateBonus = async ({ token, data = {} }) => {
  const { _id, date = '', invoiceRef = '', accountability, adminDate } = data;

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${_id}`,
      headers: {
        Authorization: token,
      },
      data: { date, invoiceRef, accountability, adminDate },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getBonuses = async ({ token, query = {}, _id }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${_id}`,
      headers: {
        Authorization: token,
      },
      params: query,
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getAllBonuses = async ({ token, query = {} }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/bonuses`,
      headers: {
        Authorization: token,
      },
      params: query,
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getAllOverdueInvoices = async ({ token, query = {} }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/overdue-invoices`,
      headers: {
        Authorization: token,
      },
      params: query,
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const addBonusPaymentDate = async ({ token, data = {} }) => {
  const { bonusId, date } = data;

  try {
    const response = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${bonusId}`,
      headers: {
        Authorization: token,
      },
      data: { date },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Field } from '../../commons/DataTable';
import ModalIafs from './ModalIafs';
import ModalAllIafs from './ModalAllIafs';

function ExtraInvoicesByMonthTable({
  data,
  query,
  onFilter,
  fetchExtraInvoicesByMonth,
}) {
  const [t] = useTranslation('COMMON');

  return (
    <DataTable
      history
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      offset={query.offset}
      limit={query.limit}
      onFilter={onFilter}
    >
      <Field
        className="txt-table-left"
        title="Amministratore"
        source="businessName"
      />
      <ModalIafs
        title="Gennaio"
        month={1}
        year={query.year}
        source="months[0].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Febbraio"
        month={2}
        year={query.year}
        source="months[1].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Marzo"
        month={3}
        year={query.year}
        source="months[2].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Aprile"
        month={4}
        year={query.year}
        source="months[3].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Maggio"
        month={5}
        year={query.year}
        source="months[4].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Giugno"
        month={6}
        year={query.year}
        source="months[5].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Luglio"
        month={7}
        year={query.year}
        source="months[6].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Agosto"
        month={8}
        year={query.year}
        source="months[7].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Settembre"
        month={9}
        year={query.year}
        source="months[8].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Ottobre"
        month={10}
        year={query.year}
        source="months[9].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Novembre"
        month={11}
        year={query.year}
        source="months[10].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalIafs
        title="Dicembre"
        month={12}
        year={query.year}
        source="months[11].count"
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
      <ModalAllIafs
        year={query.year}
        fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      />
    </DataTable>
  );
}

export default ExtraInvoicesByMonthTable;

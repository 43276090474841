import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../commons/Loading';
import {
  getAllCondoSubscriptions,
  getSusbcriptionPlan,
  removeSubscription,
} from '../../../lib/fetch/subscriptions';
import moment from 'moment';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import axios from 'axios';
import {
  Col,
  CustomInput,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  BtnOutlineGreen,
  BtnOutlineOrange,
  BtnOutlineRed,
  ContAffiliation,
  ContFilterCheckbox,
  ContFlexRight,
  H4Styled,
  H5Styled,
} from '../../../styled-components';
import ModalConfirm from '../../../commons/ModalConfirm';
import { ReactComponent as IconPlus } from '../../../images/icn_plus_green.svg';
import { ReactComponent as IconRenewed } from '../../../images/icn_rinnova.svg';
import DateInput from '../../../commons/DateInput';
import Subscription from './Subscription';
import ModalInvoiceSubscriptionDetail from './ModalInvoiceSubscriptionDetail';
import variables from '../../../variables';
import SubscriptionTable from './SubscriptionTable';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
const INITIAL_STATE = {
  _id: '',
  invoiceNumber: '',
  startDate: '',
  expireDate: '',
  adminLegalInfo: false,
};
const FORM_ERRORS = {
  startDate: false,
  startDateRequired: false,
};

const CondoSubscriptions = ({ condo = {}, condoId }) => {
  const [{ token, isAdmin, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const formRef = useRef(null);
  const [t] = useTranslation('SUBSCRIPTIONS');
  const { showLoading, hideLoading } = useLoading();
  const [total, setTotal] = useState(0);

  const [condoSubscriptions, setCondoSubscriptions] = useState([]);
  const activeSubscription = condoSubscriptions[0] || {};
  const [isASubscriber, setIsASubscriber] = useState(false);
  const [errors, setErrors] = useState(FORM_ERRORS);

  const [activeSubscriptionId, setActiveSubscriptionId] = useState(
    INITIAL_STATE._id
  );
  const [invoiceNumber, setInvoiceNumber] = useState(
    INITIAL_STATE.invoiceNumber
  );
  const [pinvInvoice, setPinvInvoice] = useState([]);
  const [startDate, setStartDate] = useState(INITIAL_STATE.startDate);
  const [expireDate, setExpireDate] = useState(INITIAL_STATE.expireDate);
  const [adminLegalInfo, setAdminLegalInfo] = useState(
    INITIAL_STATE.adminLegalInfo
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState({});

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
  });

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const { limit, offset } = query;

  const fetchCondoSubscriptions = async () => {
    showLoading();

    const { data, error, headers = {} } = await getAllCondoSubscriptions({
      condoId,
      limit,
      offset,
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setCondoSubscriptions(data.subscriptions);
    setTotal(+headers['x-total-count']);
    if (data.subscriptions.length > 0) {
      const isExpired = moment(data.subscriptions[0].expireDate).isAfter(
        moment()
      );
      setIsASubscriber(isExpired);
    }
    hideLoading();
  };
  const fetchSubscriptionPlan = async () => {
    showLoading();

    const { data, error } = await getSusbcriptionPlan({
      customerType: 'CONDO',
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setSubscriptionPlan(data.subscriptionPlan);
    hideLoading();
  };

  const createSubscription = async () => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/subscriptions/condo/${condoId}`,
      {
        startDate,
        expireDate,
        activeSubscriptionId,
        adminLegalInfo,
        activeAdminId:
          condo.matchingAdmin.length > 0 ? condo.matchingAdmin[0].id : '',
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('SUBSCRIPTIONS:ALLERT_NEW_SUBSCRIPTION'));
    fetchCondoSubscriptions();
  };

  const handleSave = async () => {
    setErrors(FORM_ERRORS);

    const submitErrors = { ...FORM_ERRORS };
    if (startDate === '') submitErrors.startDateRequired = true;

    setErrors(submitErrors);

    const hasAnyTrue = Object.values(submitErrors).some(
      (error) => error === true
    );
    if (hasAnyTrue) {
      return;
    }

    createSubscription();
  };

  const updateSubscription = async () => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/subscriptions/${activeSubscription._id}/condo`,
      {
        startDate,
        expireDate,
        adminLegalInfo,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('SUBSCRIPTIONS:ALLERT_UPDATE_SUBSCRIPTION'));
    fetchCondoSubscriptions();
  };

  const handleUpdate = async () => {
    setErrors(FORM_ERRORS);

    const submitErrors = { ...FORM_ERRORS };
    if (startDate === '') submitErrors.startDateRequired = true;

    setErrors(submitErrors);

    const hasAnyTrue = Object.values(submitErrors).some(
      (error) => error === true
    );
    if (hasAnyTrue) {
      return;
    }

    updateSubscription();
  };

  async function deleteSubscription(id) {
    showLoading();

    const { error } = await removeSubscription({
      id,
      extraInvoiceId: pinvInvoice[0]?._id,
      token,
    });

    if (error) {
      hideLoading();

      alert.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          t('COMMON:GENERIC_ERROR')
      );

      return;
    }

    fetchCondoSubscriptions();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  const handleDeleteSubscription = () => {
    deleteSubscription(activeSubscriptionId);
  };

  async function invoiceSubscription(subscriptionId, cost) {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${subscriptionId}/invoice-subscription`,
      {
        cost,
        subscriptionId,
        customerType: 'CONDO',
      },
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }
    hideLoading();

    alert.success(t('SUBSCRIPTIONS:ALLERT_NEW_SUBSCRIPTION'));
    fetchCondoSubscriptions();
  }

  useEffect(() => {
    fetchCondoSubscriptions();
    fetchSubscriptionPlan();
  }, []);

  useEffect(() => {
    const {
      _id,
      invoiceNumber,
      startDate,
      expireDate,
      pinvInvoice,
      adminLegalInfo,
    } = activeSubscription;

    setActiveSubscriptionId(_id || INITIAL_STATE._id);
    setInvoiceNumber(invoiceNumber || INITIAL_STATE.invoiceNumber);
    setStartDate(startDate || INITIAL_STATE.startDate);
    setExpireDate(expireDate || INITIAL_STATE.expireDate);
    setAdminLegalInfo(adminLegalInfo || INITIAL_STATE.adminLegalInfo);
    setPinvInvoice(pinvInvoice || []);
  }, [condoSubscriptions]);

  return (
    <>
      {isAdmin && (
        <TabContent activeTab={'1'}>
          <TabPane tabId="1">
            <ContAffiliation ref={formRef}>
              <Row>
                <Col sm="12">
                  <H5Styled>{t('DATA_SUBSCRIPTION_TITLE')}</H5Styled>
                  {condo.matchingAdmin?.length === 0 && (
                    <H4Styled style={{ marginTop: '15px' }}>
                      Questo condominio non ha amministratori attivi!
                    </H4Styled>
                  )}
                </Col>
                <Col sm="12" className="text-right">
                  <ContFlexRight>
                    {activeSubscriptionId && (
                      <>
                        {!pinvInvoice.length > 0 && (
                          <ModalConfirm
                            onConfirm={() => {
                              invoiceSubscription(
                                activeSubscriptionId,
                                Number(activeSubscription.cost?.toFixed(2)) || 0
                              );
                            }}
                            text={t('CONFIRM_INVOICE')}
                          >
                            <BtnOutlineGreen
                              type="button"
                              className="uppercase"
                            >
                              {t('INVOICE_SUBSCRIPTION')}
                            </BtnOutlineGreen>
                          </ModalConfirm>
                        )}
                        {pinvInvoice.length > 0 && (
                          <BtnOutlineGreen
                            type="button"
                            className="uppercase"
                            onClick={() =>
                              setModalState({
                                isOpen: true,
                                selectedId: pinvInvoice[0]._id,
                              })
                            }
                          >
                            {pinvInvoice[0]?.invoicePinv?.status ===
                            'to_deliver'
                              ? t('GOTO_INVOICE_DRAFT_SUBSCRIPTION')
                              : t('GOTO_INVOICE_SUBSCRIPTION')}
                          </BtnOutlineGreen>
                        )}
                      </>
                    )}
                    {condo.matchingAdmin?.length > 0 && activeSubscription._id && (
                      <ModalConfirm
                        onConfirm={handleSave}
                        text={t('CONFIRM_RENEW')}
                      >
                        <BtnOutlineOrange type="button" className="uppercase">
                          <IconRenewed fill={variables.primary} />
                          {t('RENEWED_SUBSCRIPTION')}
                        </BtnOutlineOrange>
                      </ModalConfirm>
                    )}

                    {condo.matchingAdmin?.length > 0 &&
                      !activeSubscription._id && (
                        <ModalConfirm
                          onConfirm={handleSave}
                          text={t('CONFIRM_RENEW')}
                        >
                          <BtnOutlineGreen type="button" className="uppercase">
                            <IconPlus />
                            {t('CREATE_SUBSCRIPTION')}
                          </BtnOutlineGreen>
                        </ModalConfirm>
                      )}
                    {activeSubscription._id &&
                      pinvInvoice[0]?.invoicePinv?.status !== 'delivered' && (
                        <ModalConfirm
                          onConfirm={handleDeleteSubscription}
                          text={t('CONFIRM_DELETE')}
                        >
                          <BtnOutlineRed type="button" className="uppercase">
                            {t('DELETE_SUBSCRIPTION')}
                          </BtnOutlineRed>
                        </ModalConfirm>
                      )}
                  </ContFlexRight>
                </Col>
              </Row>
              <br />
              {activeSubscription._id && condo.matchingAdmin?.length > 0 && (
                <Row>
                  <Col sm="12" md="6" lg="3">
                    <FormGroup>
                      <Label>{t('INVOICE_NUMBER')}</Label>
                      <Input
                        type="text"
                        name="invoiceNumber"
                        value={
                          pinvInvoice[0] === undefined
                            ? ''
                            : pinvInvoice[0]?.invoicePinv?.status ===
                              'to_deliver'
                            ? ''
                            : pinvInvoice[0]?.invoicePinv?.fullnumber
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <FormGroup>
                      <Label>{t('SUBSCRIPTION_START')} *</Label>
                      <DateInput
                        value={startDate}
                        setMidday
                        onChange={(value) => {
                          let startDate = moment(value).format('YYYY-MM-DD');
                          let expireDate = moment(startDate)
                            .add(1, 'year')
                            .format('YYYY-MM-DD');

                          setStartDate(startDate);
                          setExpireDate(expireDate);
                        }}
                        readOnly={activeSubscription.pinvInvoice?.length > 0}
                      />
                      {errors.startDateRequired && (
                        <FormText className="error-message">
                          {t('ERROR_START_DATE')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <FormGroup>
                      <Label>{t('SUBSCRIPTION_END')}</Label>
                      <DateInput
                        value={expireDate}
                        setMidday
                        onChange={setExpireDate}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <FormGroup>
                      <Label>{t('COST')}</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          name="cost"
                          value={
                            activeSubscription._id
                              ? activeSubscription.cost
                              : subscriptionPlan.cost?.toFixed(2)
                          }
                          readOnly
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <FormGroup>
                      <ContFilterCheckbox>
                        <CustomInput
                          type="checkbox"
                          id="adminLegalInfo"
                          name="adminLegalInfo"
                          label={t('ADMIN_LEGAL_INFO')}
                          checked={adminLegalInfo}
                          onChange={(e) => {
                            e.stopPropagation();
                            setAdminLegalInfo(!adminLegalInfo);
                          }}
                          disabled={activeSubscription.pinvInvoice?.length > 0}
                        />
                      </ContFilterCheckbox>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {activeSubscription._id && (
                <ContFlexRight>
                  <BtnOutlineGreen
                    type="button"
                    className="uppercase"
                    onClick={handleUpdate}
                  >
                    <IconPlus />
                    {t('ADD_SUBSCRIPTION')}
                  </BtnOutlineGreen>
                </ContFlexRight>
              )}
            </ContAffiliation>

            {condoSubscriptions.map((subscription, index) => {
              if (index !== 0) {
                return (
                  <Subscription
                    key={subscription._id}
                    subscription={subscription}
                    invoiceSubscription={invoiceSubscription}
                    showInvoicePinv={(id) =>
                      setModalState({ isOpen: true, selectedId: id })
                    }
                  />
                );
              }

              return null;
            })}

            <ModalInvoiceSubscriptionDetail
              modalState={modalState}
              setModalState={setModalState}
              onStatusChanged={() => fetchCondoSubscriptions()}
            />
          </TabPane>
        </TabContent>
      )}

      {!isAdmin && (
        <section>
          <SubscriptionTable
            data={condoSubscriptions}
            total={total}
            query={query}
            limit={limit}
            offset={offset}
            onFilter={onFilter}
            onStatusChanged={() => fetchCondoSubscriptions()}
          />
        </section>
      )}
    </>
  );
};

export default CondoSubscriptions;

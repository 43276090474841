import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  BooleanField,
} from '../../../commons/DataTable';
import CollabField from '../../../commons/DataTable/CollabField';

function CondosTable({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome" source="name" className="text-left" />
      <Field title="Indirizzo" source="address" className="text-left" />
      <Field title="Partita IVA" source="vatCode" />
      <Field title="Numero unità fabbricato" source="manufacturedUnitNumber" />
      <Field title="Numero di studio" source="studioNumber" />
      <CollabField title="Collaboratore" />
      <DateTimeField
        title="Inizio gestione"
        source="currentAdmin.startDate"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Fine gestione"
        source="currentAdmin.endDate"
        format="DD/MM/YYYY"
      />
      <BooleanField title="Gestione scaduta" source="currentAdmin.expired" />

      <Field title="N. POD" source="lightContracts.length" />
      <Field title="N. PDR" source="gasContracts.length" />
    </DataTable>
  );
}

export default CondosTable;

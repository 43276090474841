import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContractEONType,
  CurrencyField,
  DataTable,
  Field,
} from '../../commons/DataTable';
import { ContPaginationPage } from '../../styled-components';
import { CustomInput, FormGroup } from 'reactstrap';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';

function ContractsTabel({ data, total = 0 }) {
  const [t] = useTranslation('COMMON');

  const [contracts, setContracts] = useState(data || []);
  const [query, setQuery] = useState({
    limit: 50,
    offset: 0,
  });

  const { limit, offset } = query;

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  useEffect(() => {
    const paginatedContracts = contracts.slice(offset, offset + limit);
    setContracts(paginatedContracts);
  }, [offset, limit]);

  return (
    <>
      <DataTable
        data={contracts}
        total={data.length}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        emptyText={t('NO_DATA')}
      >
        <Field title="Nome Cliente" source="condo_name" className="text-left" />
        <Field title="Partita IVA Cliente" source="condo_vat_cf" />
        <ContractEONType title="Nome prodotto" source="product_name" />
        <Field title="POD/PDR" source="pod_pdr" />
        <CurrencyField
          title="Quota responsabile"
          source="adminShare"
          className="txt-table-right"
        />
      </DataTable>

      <ContPaginationPage>
        <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

        <FormGroup>
          <CustomInput
            type="select"
            className="pagination-select"
            id="perPage"
            name="perPage"
            value={query.limit}
            onChange={({ target: { value: limit } }) => {
              onFilter({ limit });
            }}
          >
            {generatePaginationOptions()}
          </CustomInput>
        </FormGroup>
      </ContPaginationPage>
    </>
  );
}

export default ContractsTabel;

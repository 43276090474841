import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  Col,
  CustomInput,
  FormGroup,
  Label,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  ContAffiliation,
  ContFilterYear,
  ContPaginationPage,
  H5Styled,
} from '../../../../styled-components';
import generateYearOptions from '../../../../lib/helpers/generateYearOptions';
import SearchInput from '../../../../commons/SearchInput';
import RoyaltyTable from '../RoyaltyTable';
import TotalRevenuesRow from '../TotalRows/TotalTrRevenue';
import generatePaginationOptions from '../../../../lib/helpers/generatePaginationOptions';
import generateMonthOptions from '../../../../lib/helpers/generateMonthOptions';
import variables from '../../../../variables';

const VariableRevenuesDetailPresentational = ({
  data = {},
  query,
  total,
  onFilter,
  profile,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [{ isAdmin }] = useAuth();
  const alert = useAlert();
  const { categories = {} } = data;

  const [amount, setAmount] = useState(0);
  const { variableRevenues = {} } = categories;
  const { data: dataRev = [] } = variableRevenues;

  const origin = window.location.origin;
  let hash = window.location.hash.split('/');
  hash.pop();
  const prevPath =
    origin +
    '/' +
    hash.join('/') +
    `${query.year ? '?year=' + query.year : ''}`;

  const getVariableRevenuesTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce((prev, cur) => {
      const { payments } = cur || {};
      return (
        prev +
        (payments || []).reduce((acc, payment) => {
          return acc + ((payment && payment.gross) || 0);
        }, 0)
      );
    }, 0);
    const netTotals = dataRev.reduce((prev, cur) => prev + cur.net, 0);
    const grossTotals = dataRev.reduce((prev, cur) => prev + cur.gross, 0);
    const adminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.adminShare,
      0
    );
    const earnedAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.earnedAdminShare,
      0
    );
    const billableAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.billableAdminShare,
      0
    );
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      adminShareTotals,
      earnedAdminShareTotals,
      billableAdminShareTotals,
    };
  };

  return (
    <>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('TITLE_FILTER_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          <div
            style={{
              fontWeight: 700,
              color: '#333333',
              marginBottom: '20px',
              fontSize: '20px',
            }}
          >
            <a
              style={{
                color: variables.primary,
              }}
              href={prevPath}
            >
              Rendicontazioni
            </a>{' '}
            / <a>{t('ROYALTY:TITLE')}</a>
          </div>
          <ContAffiliation key={1}>
            <Row className="compensi-variabili-resp">
              <Col sm="6" md="6" lg="6">
                <SearchInput
                  query={{ search: query && query.supplier }}
                  onFilter={({ search }) => onFilter({ supplier: search })}
                  label={t('SEARCH:BY_SUPPLIER')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6">
                <SearchInput
                  query={{ search: query && query.number }}
                  onFilter={({ search }) => onFilter({ number: search })}
                  label={t('SEARCH:INVOICE_NUMBER')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectAccountabilityYear"
                          name="accountabilityYear"
                          value={query && query.accountabilityYear}
                          onChange={({
                            target: { value: accountabilityYear },
                          }) => {
                            onFilter({ accountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col sm="6" md="6" lg="6">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="accountabilityMonth"
                          name="accountabilityMonth"
                          disabled={!query.accountabilityYear}
                          value={
                            query && query.accountabilityMonth === null
                              ? ''
                              : query.accountabilityMonth
                          }
                          onChange={({
                            target: { value: accountabilityMonth },
                          }) => onFilter({ accountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
            <br />
            <RoyaltyTable
              data={dataRev || []}
              total={total}
              query={query}
              onFilter={onFilter}
              profile={profile}
              showEmptyTableMessage={true}
              bottomRow={
                <TotalRevenuesRow
                  totals={getVariableRevenuesTotals(dataRev)}
                  route="variable-revenues"
                  activeRoute={false}
                />
              }
            />
            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </>
  );
};

export default VariableRevenuesDetailPresentational;

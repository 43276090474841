import React from 'react';
import { TabContent, TabPane, Form, Label } from 'reactstrap';
import '../../ContractsAdmin/MyContracts.scss';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { ReactComponent as IconBuilding } from '../../../images/icn_buildings.svg';
import {
  ContFilterYear,
  ContFlexRight,
  ContPaginationPage,
} from '../../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';

import SearchInput from '../../../commons/SearchInput';
import ContractsTable from '../../ContractsAdmin/ContractsTable';
import Footer from '../../../commons/Footer';
import Header from '../../../commons/Header';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import { USER_ADMIN_TYPES } from '../../../App';
import ContractsTableBackoffice from '../../ContractsAdmin/ContractsTableBackoffice';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';

const EnergyPresentational = ({
  data,
  profile,
  query,
  total,
  totPod,
  totPdr,
  onFilter,
  onCondoUpdate,
  onChangeAdmin,
  onExtendAdminManagement,
  checkVatCode,
  userType = '',
}) => {
  const [t] = useTranslation('CONTRACTS');
  const { offset, limit, customerType } = query;

  return (
    <section>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <section>
            <Header
              title={t('PAGES:CONTRACTS')}
              icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
            />

            <Row className="align-items-center">
              <Col className="">
                <ContFlexRight>
                  Contratti POD:&nbsp;<strong>{totPod}</strong>&nbsp; |
                  Contratti PDR:&nbsp;<strong>{totPdr}</strong>
                </ContFlexRight>
              </Col>
            </Row>

            <Row className="search-add-cont m-t-20" style={{ rowGap: '20px' }}>
              <Col sm="12" md="6" lg="3" className="select-wrapper">
                <ContFilterYear
                  className="no-width"
                  style={{ marginBottom: '0' }}
                >
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_SIGNIN_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectContractSigningYear"
                          name="contractSigningYear"
                          value={query && query.contractSigningYear}
                          onChange={({
                            target: { value: contractSigningYear },
                          }) => {
                            onFilter({ contractSigningYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>

              <Col sm="12" md="6" lg="3" className="select-wrapper">
                <ContFilterYear
                  className="no-width"
                  style={{ marginBottom: '0' }}
                >
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_SIGNIN_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="contractSigningMonth"
                          name="contractSigningMonth"
                          disabled={!query.contractSigningYear}
                          value={query && query.contractSigningMonth}
                          onChange={({
                            target: { value: contractSigningMonth },
                          }) => onFilter({ contractSigningMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              {!(profile && profile._id) && (
                <Col sm="6" md="6" lg="3" xl="3">
                  <SearchInput
                    query={{ search: query && query.admin }}
                    onFilter={({ search }) => onFilter({ admin: search })}
                    label={t('SEARCH:ADMINISTRATOR')}
                  />
                </Col>
              )}
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.customer }}
                  onFilter={({ search }) => onFilter({ customer: search })}
                  label={t('SEARCH:CUSTOMER')}
                />
              </Col>
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.pod_pdr }}
                  onFilter={({ search }) => onFilter({ pod_pdr: search })}
                  label={t('SEARCH:POD_PDR')}
                />
              </Col>
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.condo_vat_cf }}
                  onFilter={({ search }) => onFilter({ condo_vat_cf: search })}
                  label={t('SEARCH:CLIENT_VAT_CODE')}
                />
              </Col>
              <Col sm="12" md="4" lg="3" xl="3" className="select-wrapper">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>Tipo cliente</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="customerType"
                          name="customerType"
                          value={customerType}
                          onChange={({ target: { value: customerType } }) =>
                            onFilter({ customerType })
                          }
                        >
                          <option value="" defaultChecked>
                            Tutti
                          </option>
                          <option value="condos">Condomini</option>
                          <option value="customerExtra">Clienti privati</option>
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
          </section>

          <section className="m-t-20">
            {((userType === USER_ADMIN_TYPES.SUPER_ADMIN ||
              userType === USER_ADMIN_TYPES.SUPERVISOR) && (
              <ContractsTable
                data={data}
                total={total}
                profileType={profile.type}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                onCondoUpdate={onCondoUpdate}
                onChangeAdmin={onChangeAdmin}
                profile={profile}
                onExtendAdminManagement={onExtendAdminManagement}
                truncateTable={profile && profile._id}
                checkVatCode={checkVatCode}
                userType={userType}
              />
            )) || (
              <ContractsTableBackoffice
                data={data}
                total={total}
                profileType={profile.type}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                onCondoUpdate={onCondoUpdate}
                onChangeAdmin={onChangeAdmin}
                profile={profile}
                onExtendAdminManagement={onExtendAdminManagement}
                truncateTable={profile && profile._id}
                checkVatCode={checkVatCode}
                userType={userType}
              />
            )}

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit: limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </section>
          <Footer />
        </TabPane>
      </TabContent>
    </section>
  );
};

export default EnergyPresentational;

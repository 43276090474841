import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';

const TotalTr = ({ totals = {}, profileType = '' }) => {
  const { extraShareTotals = 0, adminShareTotals = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(extraShareTotals)}</td>
      <td className="txt-table-right">{formatCurrency(adminShareTotals)}</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;

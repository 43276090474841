import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BtnOutlineGreen,
  ContAffiliation,
  ContFilterCheckbox,
  ContFlexRight,
  H5Styled,
  HeaderCollapse,
} from '../../../styled-components';
import {
  Badge,
  Col,
  Collapse,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap';
import moment from 'moment';
import ModalConfirm from '../../../commons/ModalConfirm';
import DateInput from '../../../commons/DateInput';

const Subscription = ({
  subscription = {},
  invoiceSubscription,
  showInvoicePinv,
}) => {
  const [t] = useTranslation('SUBSCRIPTIONS');
  const [isOpen, setIsOpen] = useState(false);

  const {
    startDate,
    expireDate,
    cost,
    adminLegalInfo,
    payments = [],
  } = subscription;

  const toggle = () => setIsOpen(!isOpen);

  const isSubscriptionPaid =
    subscription.pinvInvoice[0]?.sailInvoice?.collected;

  return (
    <>
      <HeaderCollapse onClick={toggle}>
        <Row>
          <Col xs="8">
            <h6>
              {t('PAST_SUBSCRIPTION_TITLE')}{' '}
              <i>
                da {moment(startDate).format('DD/MM/YYYY')} a{' '}
                {moment(expireDate).format('DD/MM/YYYY')}
              </i>
            </h6>
          </Col>
          <Col xs="4" className="text-right">
            {isSubscriptionPaid ? (
              <Badge className="pay">INCASSATA</Badge>
            ) : (
              <Badge className="not-pay">NON INCASSATA</Badge>
            )}

            {subscription?.pinvInvoice.length > 0 ? (
              <Badge className="pay">
                {subscription?.pinvInvoice[0]?.invoicePinv?.status ===
                'to_deliver'
                  ? 'BOZZA FATTURA'
                  : 'FATTURATA'}
              </Badge>
            ) : (
              <Badge className="not-pay">NON FATTURATA</Badge>
            )}

            <i className="fas fa-chevron-down"></i>
          </Col>
        </Row>
      </HeaderCollapse>

      <Collapse isOpen={isOpen}>
        <ContAffiliation>
          <Row>
            <Col sm="12" md="5">
              <H5Styled>{t('DATA_SUBSCRIPTION_TITLE')}</H5Styled>
            </Col>
            <Col sm="12" md="7" className="text-right">
              <ContFlexRight>
                {!subscription?.pinvInvoice.length > 0 && (
                  <ModalConfirm
                    onConfirm={() => {
                      invoiceSubscription(subscription._id);
                    }}
                    text={t('CONFIRM_INVOICE')}
                  >
                    <BtnOutlineGreen type="button" className="uppercase">
                      {t('INVOICE_SUBSCRIPTION')}
                    </BtnOutlineGreen>
                  </ModalConfirm>
                )}
                {subscription?.pinvInvoice.length > 0 && (
                  <BtnOutlineGreen
                    type="button"
                    className="uppercase"
                    onClick={() => {
                      showInvoicePinv(subscription?.pinvInvoice[0]._id);
                    }}
                  >
                    {subscription?.pinvInvoice[0]?.invoicePinv?.status ===
                    'to_deliver'
                      ? t('GOTO_INVOICE_DRAFT_SUBSCRIPTION')
                      : t('GOTO_INVOICE_SUBSCRIPTION')}
                  </BtnOutlineGreen>
                )}
                {/* {subscription?.pinvInvoice[0]?.invoicePinv?.status !==
                  'delivered' && (
                  <ModalConfirm
                    onConfirm={handleDeleteAffiliation}
                    text={t('CONFIRM_DELETE')}
                  >
                    <BtnOutlineRed type="button" className="uppercase">
                      {t('DELETE_AFFILIATION')}
                    </BtnOutlineRed>
                  </ModalConfirm>
                )} */}
              </ContFlexRight>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('INVOICE_NUMBER')}</Label>
                <Input
                  type="text"
                  name="invoiceNumber"
                  value={
                    subscription?.pinvInvoice[0]?.invoicePinv?.status ===
                    'to_deliver'
                      ? ''
                      : subscription?.pinvInvoice[0]?.invoicePinv?.fullnumber
                  }
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('SUBSCRIPTION_START')}</Label>
                <DateInput value={startDate} readOnly />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('SUBSCRIPTION_END')}</Label>
                <DateInput value={expireDate} readOnly />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('COST')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="cost"
                    value={cost.toFixed(2)}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <ContFilterCheckbox>
                  <CustomInput
                    type="checkbox"
                    label={t('ADMIN_LEGAL_INFO')}
                    checked={adminLegalInfo}
                    disabled={true}
                  />
                </ContFilterCheckbox>
              </FormGroup>
            </Col>
          </Row>
        </ContAffiliation>
      </Collapse>
    </>
  );
};

export default Subscription;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DateTimeField } from '../../../commons/DataTable';
import AdminBalancesField from '../../../commons/DataTable/AdminBalanceField';

function CondoBalancesTable({ data, total, offset, limit, onFilter, profile }) {
  const [t] = useTranslation();

  const dataSorted = data.sort((a, b) => b.year - a.year);

  return (
    <DataTable
      data={dataSorted}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <DateTimeField
        title="Data Inizio"
        source="startDate"
        format="DD/MM/YYYY"
      />
      <DateTimeField title="Data Fine" source="endDate" format="DD/MM/YYYY" />
      <AdminBalancesField title="Amministratori" />
    </DataTable>
  );
}

export default CondoBalancesTable;

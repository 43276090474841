import React from 'react';
import { Badge } from 'reactstrap';

import moment from 'moment';

const SubscriptionBadgeField = ({ value, data, ...props }) => {
  const today = moment();
  const startDate = moment(data.startDate);
  const expireDate = moment(data.expireDate);
  const hasInvoice = data.pinvInvoice[0]?.sailInvoice;

  const isExpired = expireDate.isBefore(today, 'day');
  const isActive = today.isBetween(startDate, expireDate, null, '[]');
  const toBeActivated = startDate.isAfter(today, 'day');

  let badgeText = '';
  let badgeClass = '';

  if (isActive && hasInvoice) {
    badgeText = 'Attivo';
    badgeClass = 'master-yes';
  } else if (isActive && !hasInvoice) {
    badgeText = 'Da pagare';
    badgeClass = 'not-paid';
  } else if (isExpired) {
    badgeText = 'Terminato';
    badgeClass = 'master-no';
  } else if (toBeActivated) {
    badgeText = 'Non ancora attivo';
    badgeClass = 'not-yet-active';
  }

  return (
    <div className={badgeClass} {...props}>
      <Badge>{badgeText}</Badge>
    </div>
  );
};

export default SubscriptionBadgeField;

import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';
import { ADMIN_TYPES, INVOICES_ALLOWED_TYPES } from '../../../App';

const TotalTr = ({ totals = {}, profileType = '' }) => {
  const {
    netTotals = 0,
    grossTotals = 0,
    paymentsTotals = 0,
    extraGrossTotals = 0,
    adminShareTotals = 0,
    extraShareTotals = 0,
  } = totals;

  return (
    <tr className="summary-tr">
      {INVOICES_ALLOWED_TYPES.includes(profileType) && <td>-</td>}
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(netTotals)}</td>
      <td className="txt-table-right">{formatCurrency(grossTotals)}</td>
      <td className="txt-table-right">{formatCurrency(extraShareTotals)}</td>
      <td className="txt-table-right">{formatCurrency(adminShareTotals)}</td>
      <td className="txt-table-right">{formatCurrency(paymentsTotals)}</td>
      {ADMIN_TYPES.includes(profileType) && <td>-</td>}
      {ADMIN_TYPES.includes(profileType) && <td>-</td>}
      {ADMIN_TYPES.includes(profileType) && <td>-</td>}
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      {INVOICES_ALLOWED_TYPES.includes(profileType) && <td>-</td>}
    </tr>
  );
};

export default TotalTr;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContFilterYear,
  BtnOutlineOrange,
  BtnOutlineGreen,
  ContPaginationPage,
} from '../../../../styled-components';
import SearchInput from '../../../../commons/SearchInput';
import generateMonthOptions from '../../../../lib/helpers/generateMonthOptions';
import TotalEnergyRevenuesRow from '../TotalRows/TotalTrEnergyRevenue';
import generateYearOptions from '../../../../lib/helpers/generateYearOptions';
import { useAuth } from '../../../../commons/Auth';
import variables from '../../../../variables';
import ReportingModal from '../../../../commons/ReportingModal';
import generatePaginationOptions from '../../../../lib/helpers/generatePaginationOptions';
import EnergyRoyaltyTable from '../EnergyRoyaltyTable';

const VarRevEnergyDetailPresentational = ({
  data = {},
  profile,
  total,
  query,
  onFilter,
  onAddBonus,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [{ isAdmin }] = useAuth();
  const { categories = {} } = data;
  const { variableRevenuesEnergy = {} } = categories;
  const [amount, setAmount] = useState(0);
  const alert = useAlert();
  const { data: dataRevEnergy = [] } = variableRevenuesEnergy;

  const origin = window.location.origin;
  let hash = window.location.hash.split('/');
  hash.pop();
  const prevPath =
    origin +
    '/' +
    hash.join('/') +
    `${query.year ? '?year=' + query.year : ''}`;

  const getVariableRevenuesTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce((prev, cur) => {
      const { payments } = cur || {};
      return (
        prev +
        (payments || []).reduce((acc, payment) => {
          return acc + ((payment && payment.gross) || 0);
        }, 0)
      );
    }, 0);
    const netTotals = dataRev.reduce((prev, cur) => prev + cur.net, 0);
    const grossTotals = dataRev.reduce((prev, cur) => prev + cur.gross, 0);
    const adminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.adminShare,
      0
    );
    const earnedAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.earnedAdminShare,
      0
    );
    const billableAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.billableAdminShare,
      0
    );
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      adminShareTotals,
      earnedAdminShareTotals,
      billableAdminShareTotals,
    };
  };

  return (
    <>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('TITLE_FILTER_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          <div
            style={{
              fontWeight: 700,
              color: '#333333',
              marginBottom: '20px',
              fontSize: '20px',
            }}
          >
            <a
              style={{
                color: variables.primary,
              }}
              href={prevPath}
            >
              Rendicontazioni
            </a>{' '}
            / <a>{t('ENERGY:ROYALTIES_TITLE')}</a>
          </div>
          <ContAffiliation key={2}>
            <Row className="compensi-variabili-resp">
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.energyPod }}
                  onFilter={({ search }) => onFilter({ energyPod: search })}
                  label={t('SEARCH:POD_PDR')}
                />
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <SearchInput
                  query={{ search: query && query.energyCondoVat }}
                  onFilter={({ search }) =>
                    onFilter({ energyCondoVat: search })
                  }
                  label={t('SEARCH:CLIENT_VAT_CODE')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectEnergyAccountabilityYear"
                          name="energyAccountabilityYear"
                          value={query && query.energyAccountabilityYear}
                          onChange={({
                            target: { value: energyAccountabilityYear },
                          }) => {
                            onFilter({ energyAccountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="energyAccountabilityMonth"
                          name="energyAccountabilityMonth"
                          disabled={!query.energyAccountabilityYear}
                          value={
                            query && query.energyAccountabilityMonth === null
                              ? ''
                              : query.energyAccountabilityMonth
                          }
                          onChange={({
                            target: { value: energyAccountabilityMonth },
                          }) => onFilter({ energyAccountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
            <br />
            <EnergyRoyaltyTable
              data={dataRevEnergy || []}
              total={total}
              query={query}
              onFilter={onFilter}
              showEmptyTableMessage={true}
              bottomRow={
                <TotalEnergyRevenuesRow
                  totals={getVariableRevenuesTotals(dataRevEnergy)}
                  route="variable-revenues-energy"
                  activeRoute={false}
                />
              }
            />
            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </>
  );
};

export default VarRevEnergyDetailPresentational;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import RoyaltiesPresentational from './RoyaltiesPresentational';
import moment from 'moment';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

const Royalties = ({ profile = {} }) => {
  const [invoices, setInvoices] = useState([]);
  const [condos, setCondos] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { type: profileType = '' } = profile;

  const [total, setTotal] = useState(0);
  const [{ isAdmin, token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const [query, setQuery] = useQueryParams({
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 20),
    year: NumberParam,
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    validated: withDefault(StringParam, ''),
    paid: withDefault(StringParam, ''),
    toBill: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    search,
    year,
    accountabilityYear,
    accountabilityMonth,
    admin,
    customer,
    number,
    supplier,
    validated,
    paid,
    toBill,
  } = query;

  useEffect(() => {
    if (profile._id) fetchInvoices();
  }, [
    limit,
    offset,
    search,
    year,
    accountabilityYear,
    accountabilityMonth,
    profile._id,
    admin,
    customer,
    number,
    supplier,
    validated,
    paid,
    toBill,
  ]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          search,
          year,
          accountabilityYear,
          accountabilityMonth,
          admin,
          customer,
          number,
          supplier,
          validated,
          paid,
          toBill,
        },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { headers, data } = response;

    setInvoices(
      data.map((item) => {
        return {
          ...item,
          extraGross: item?.net * 0.15,
          extraShare: item?.net * 0.15 - item.adminShare,
        };
      })
    );
    setTotal(+headers['x-total-count']);
    hideLoading();
  };

  const onInvoiceUpdate = async (data) => {
    const { _id } = data;
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${_id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onAddPayment = async ({ invoiceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const deleteInvoice = async (invoiceId) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/invoices/admin/${invoiceId}`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_DELETE'));
    fetchInvoices();
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  function setFilterYear(value) {
    setQuery({ year: value.year });
  }

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myCompensiVariabiliSearch', queryDiff.search);
    }
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') || // search deleted
      (queryDiff.validated && queryDiff.validated !== validated) ||
      (queryDiff.toBill && queryDiff.toBill !== toBill) ||
      (queryDiff.paid && queryDiff.paid !== paid) || // first search
      (paid && queryDiff.paid === '') ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const onSelectChange = (row) => {
    if (selectedInvoices.includes(row._id)) {
      setSelectedAll(false);
      setCheckedRows((checkedRows) =>
        checkedRows.filter(({ _id }) => _id !== row._id)
      );
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== row._id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, row._id]);
    setCheckedRows((checkedRows) => [...checkedRows, row]);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      setCheckedRows([]);
      return setSelectedInvoices([]);
    }
    setCheckedRows(
      invoices.filter(
        (invoice) => invoice.pinvInvoice?.length === 0 && invoice.paid
      )
    );
    setSelectedInvoices(
      invoices
        .filter((invoice) => invoice.pinvInvoice?.length === 0 && invoice.paid)
        .map(({ _id }) => _id)
    );
  };

  const fetchAdminCondos = async (adminId) => {
    if (!adminId) {
      setCondos([]);
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/condos/${adminId}`,
      {
        params: {
          limit: 300,
        },
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;

    setCondos(data.condos);
  };

  const fetchAdminCustomers = async (adminId) => {
    if (!adminId) {
      setCustomers([]);
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/customer-extra?profileId=${adminId}`,
      {
        params: {
          limit: 300,
        },
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    setCustomers(data);
  };

  const onInvoice = async () => {
    showLoading();

    const accountabilityYear = moment(checkedRows[0]?.accountability).year();

    for (const row of checkedRows) {
      const rowYear = moment(row.accountability).year();
      if (rowYear !== accountabilityYear) {
        alert.error(
          'Le fatture selezionate presentano anni di rendicontazione differenti.'
        );
        hideLoading();
        return;
      }
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${profile._id}/invoices`,
      {
        selectedInvoices,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));

    fetchInvoices();
    setSelectedInvoices([]);
    setSelectedAll(false);
  };

  const searchEstateAgents = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/estateAgents`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  return (
    <RoyaltiesPresentational
      data={invoices}
      isAdmin={isAdmin}
      query={query}
      total={total}
      onFilter={onFilter}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      setFilterYear={setFilterYear}
      searchAdministrators={searchAdministrators}
      searchEstateAgents={searchEstateAgents}
      onInvoiceUpdate={onInvoiceUpdate}
      onAddPayment={onAddPayment}
      onSelectAll={onSelectAll}
      fetchAdminCondos={fetchAdminCondos}
      fetchAdminCustomers={fetchAdminCustomers}
      condos={condos}
      customersExtra={customers}
      profileType={profileType}
      onInvoice={onInvoice}
      selectedAll={selectedAll}
      onStatusChanged={() => fetchInvoices()}
      deleteInvoice={deleteInvoice}
    />
  );
};

export default Royalties;

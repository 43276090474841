import axios from 'axios';

export const getAllProfileSubscriptions = async ({
  profileId,
  token,
  limit,
  offset,
}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/subscriptions/profile/${profileId}`,
      params: { limit, offset },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getSusbcriptionPlan = async ({ customerType, token }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/subscriptions/plan`,
      params: { customerType },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getAllCondoSubscriptions = async ({
  condoId,
  token,
  limit,
  offset,
}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/subscriptions/condo/${condoId}`,
      params: { limit, offset },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const removeSubscription = async ({
  id,
  extraInvoiceId,
  token,
} = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/subscriptions/${id}`,
      params: {
        extraInvoiceId,
      },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

import React, { useEffect, useState } from 'react';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../commons/Footer';
import './Agreements.scss';
import SupplierCondoAgreements from './SupplierCondoAgreements';
import SupplierAdminAgreements from './SupplierAdminAgreements';
import { getAllProfileSubscriptions } from '../../lib/fetch/subscriptions';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import { ADMIN_TYPES } from '../../App';
import moment from 'moment';

const Agreements = (props) => {
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token, isAdmin, profile = {} } = {}] = useAuth();

  const [profileSubscriptions, setProfileSubscriptions] = useState([]);
  const [isASubscriber, setIsASubscriber] = useState(false);

  const { history, location, match = {} } = props;
  const { url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();

  const fetchProfileSubscriptions = async () => {
    showLoading();

    const { data, error } = await getAllProfileSubscriptions({
      profileId: profile._id,
      limit: 1,
      offset: 0,
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfileSubscriptions(data.subscriptions);
    hideLoading();
  };

  const handleIsASubscriber = () => {
    if (profile.type === 'SUPPLIER') {
      setIsASubscriber(true);
    } else if (
      ADMIN_TYPES.includes(profile.type) &&
      profileSubscriptions.length > 0
    ) {
      const activeSubscription = profileSubscriptions[0];
      const today = moment();
      const startDate = moment(activeSubscription.startDate);
      const expireDate = moment(activeSubscription.expireDate);
      const hasInvoice = activeSubscription.pinvInvoice[0]?.sailInvoice;

      const isActive = today.isBetween(startDate, expireDate, null, '[]');
      if (isActive && hasInvoice) {
        setIsASubscriber(true);
      }
    }
  };

  useEffect(() => {
    fetchProfileSubscriptions();
  }, []);

  useEffect(() => {
    handleIsASubscriber();
  }, [profileSubscriptions]);

  if (pathname === url) {
    return <Redirect to={`${url}/supplier-condo`} />;
  }

  if (pathname.split('/')[2] === 'supplier-admin' && !isASubscriber) {
    return <Redirect to={`${url}/supplier-condo`} />;
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:AGREEMENTS')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <section className="m-tb-20">
          <Nav tabs>
            <NavItem>
              <NavLink
                tag={Link}
                to={`${match.url}/supplier-condo`}
                className={classNames({ active: tab === 'supplier-condo' })}
              >
                {t('SUPPLIER_CONDO')}
              </NavLink>
            </NavItem>

            {isASubscriber && (
              <NavItem>
                <NavLink
                  tag={Link}
                  to={`${match.url}/supplier-admin`}
                  className={classNames({ active: tab === 'supplier-admin' })}
                >
                  {t('SUPPLIER_ADMIN')}
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <Switch>
            <Route
              path={`${match.url}/supplier-condo`}
              component={(props) => <SupplierCondoAgreements {...props} />}
            />

            <Route
              path={`${match.url}/supplier-admin`}
              component={(props) => <SupplierAdminAgreements {...props} />}
            />
          </Switch>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default withRouter(Agreements);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  Field,
} from '../../commons/DataTable';
import { ContPaginationPage } from '../../styled-components';
import { CustomInput, FormGroup } from 'reactstrap';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';

function InvoiceTabel({ data, total = 0 }) {
  const [t] = useTranslation('COMMON');

  const [invoices, setInvoices] = useState(data || []);
  const [query, setQuery] = useState({
    limit: 50,
    offset: 0,
  });

  const { limit, offset } = query;

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  useEffect(() => {
    const paginatedInvoices = invoices.slice(offset, offset + limit);
    setInvoices(paginatedInvoices);
  }, [offset, limit]);

  return (
    <>
      <DataTable
        data={invoices}
        total={data.length}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        emptyText={t('NO_DATA')}
      >
        <Field
          title="Nome Fornitore"
          source="supplier.businessName"
          className="text-left"
        />
        <Field title="Numero Fattura" source="number" />
        <DateTimeField title="Data Fattura" source="date" format="DD/MM/YYYY" />
        <CurrencyField title="Imponibile" source="net" />
        <CurrencyField title="Importo da Incassare" source="gross" />
        <CurrencyField
          title="Quota responsabile"
          source="adminShare"
          className="txt-table-right"
        />
      </DataTable>

      <ContPaginationPage>
        <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

        <FormGroup>
          <CustomInput
            type="select"
            className="pagination-select"
            id="perPage"
            name="perPage"
            value={query.limit}
            onChange={({ target: { value: limit } }) => {
              onFilter({ limit });
            }}
          >
            {generatePaginationOptions()}
          </CustomInput>
        </FormGroup>
      </ContPaginationPage>
    </>
  );
}

export default InvoiceTabel;

import './Invoices.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useLoading } from '../../commons/Loading';
import InvoicesPresentational from './InvoicesPresentational';
import studioInvoicesAPIs from '../../lib/fetch/studioInvoicesAPIs';

function StudioInvoices({ profile }) {
  const [invoices, setInvoices] = useState([]);
  const [condos, setCondos] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [hasImportedStudioInvoices, setHasImportedStudioInvoices] = useState(
    false
  );
  const [
    studioInvoicesImportFeedbackData,
    setStudioInvoicesImportFeedbackData,
  ] = useState({});

  const toggleShowImportFeedback = () =>
    setHasImportedStudioInvoices((v) => !v);

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    limit: withDefault(StringParam, '900'),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityMonth: NumberParam,
  });

  const { all, customer, number, year, accountabilityMonth } = query;

  useEffect(() => {
    fetchAdminCondos();
    fetchAdminCustomers();
  }, [profile._id]);

  useEffect(() => {
    fetchStudioInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all, customer, number, year, accountabilityMonth]);

  const fetchStudioInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/studio-invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: query,
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setInvoices(response.data.data);
    hideLoading();
  };

  const fetchAdminCondos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/condos/${profile._id}`,
      {
        params: {
          limit: 300,
        },
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;

    setCondos(data.condos);
  };

  const fetchAdminCustomers = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/customer-extra?profileId=${profile._id}`,
      {
        params: {
          limit: 300,
        },
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;

    setCustomers(data);
  };

  const onNewInvoiceConfirm = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/studio-invoices`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const capsulateError = { error: response };
      const { error } = capsulateError;
      const { response: errorResponse, message: errorMessage } = error;
      const { data: errorData } = errorResponse || {};
      const { message: dataMessage } = errorData || {};
      alert.error(dataMessage || errorMessage || t('COMMON:GENERIC_ERROR'));
      return hideLoading();
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchStudioInvoices();
  };

  const onInvoiceUpdate = async (data) => {
    const { _id } = data;
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/studio-invoices/${_id}`,
      { ...data, admin: data.admin._id },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchStudioInvoices();
  };

  const onAddPayment = async ({ invoiceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/studio-invoices/${invoiceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchStudioInvoices();
  };

  const onDeletePayment = async ({ invoiceId, paymentIndex }) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments/${paymentIndex}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchStudioInvoices();
  };

  const onValidateInvoice = async (invoiceId) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/studio-invoices/${invoiceId}/validate`,
      { validated: true },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchStudioInvoices();
  };

  const onInvoiceDelete = async (invoiceId) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/studio-invoices/${invoiceId}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchStudioInvoices();
  };

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myInvoiceSearch', queryDiff.search);
    }
    setQuery(queryDiff);
  }

  const onImportStudioInvoices = async (file) => {
    showLoading();
    const { error, data } = await studioInvoicesAPIs.importStudioInvoices({
      token,
      file,
    });
    hideLoading();
    toggleShowImportFeedback();
    setStudioInvoicesImportFeedbackData({
      ...data,
      validationErrors: error?.response?.data,
    });
    fetchStudioInvoices();
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } else {
      alert.success(t('COMMON:SUCCESS_UPLOAD'));
    }
  };

  return (
    <InvoicesPresentational
      data={invoices}
      query={query}
      onFilter={onFilter}
      condos={condos}
      customersExtra={customers}
      onNewInvoiceConfirm={onNewInvoiceConfirm}
      onInvoiceUpdate={onInvoiceUpdate}
      onAddPayment={onAddPayment}
      onDeletePayment={onDeletePayment}
      onValidateInvoice={onValidateInvoice}
      onInvoiceDelete={onInvoiceDelete}
      toggleShowImportFeedback={toggleShowImportFeedback}
      onImportStudioInvoices={onImportStudioInvoices}
      studioInvoicesImportFeedbackData={studioInvoicesImportFeedbackData}
      hasImportedStudioInvoices={hasImportedStudioInvoices}
    />
  );
}

export default StudioInvoices;

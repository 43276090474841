import React, { useEffect, useState } from 'react';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import ExtraInvoiceByMonthPresentational from './ExtraInvoiceByMonthPresentational';
import axios from 'axios';
import './IafsByMonth.scss';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function IafsByMonth(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    year: withDefault(NumberParam, new Date().getFullYear()),
  });
  const { offset, limit, year } = query;

  const fetchExtraInvoicesByMonth = async (upsert = false) => {
    showLoading();
    setData([]);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/extra-invoices-by-month`,
      {
        headers: { Authorization: token },
        params: { offset, limit, year, upsert },
      }
    );
    hideLoading();
    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }
    setData(response.data || []);
    setTotal(Number(response.headers['x-total-count']));
  };

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  useEffect(() => {
    fetchExtraInvoicesByMonth();
  }, [offset, limit, year]);

  return (
    <ExtraInvoiceByMonthPresentational
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
      fetchExtraInvoicesByMonth={fetchExtraInvoicesByMonth}
      {...props}
    />
  );
}

export default IafsByMonth;

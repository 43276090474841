import './MyContracts.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Form, Button } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconBuilding } from '../../images/icn_buildings.svg';
import {
  ContFilterYear,
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import ContractsTable from './ContractsTable';
import { useAlert } from 'react-alert';
import ContractsImportFeedbackModal from './ContractsImportFeedbackModal';
import ExportCSVButton from './ExportCSVButton';
import { ADMIN_TYPES, AGENT_TYPES } from '../../App';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';

const ContractsPresentational = ({
  data,
  formattedData,
  profile,
  profileScore,
  query,
  total,
  onFilter,
  onCondoUpdate,
  onChangeAdmin,
  onExtendAdminManagement,
  checkVatCode,
  onImportContract,
  contractsImportFeedbackData,
  hasImportedContracts,
  toggleShowImportFeedback,
  totPod = 0,
  totPdr = 0,
}) => {
  const [t] = useTranslation('CONTRACTS');
  const { offset, limit, customerType } = query;
  const alert = useAlert();

  function handleImportFile(event) {
    const file = event.target?.files[0];

    const fileSize = (Number(file?.size || 0) / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Sono ammessi file di massimo 20MB');
    } else {
      const data = new FormData();
      data.append('file', file);
      onImportContract(data);
    }
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <ContractsImportFeedbackModal
          data={contractsImportFeedbackData}
          toggle={toggleShowImportFeedback}
          isOpen={hasImportedContracts}
        />
        <section>
          <Header
            title={t('PAGES:CONTRACTS')}
            icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row className="align-items-center">
            {ADMIN_TYPES.includes(profile.type) && (
              <Col className="">
                <ContFlexLeft>
                  {t('SCORE:CONTRACTS_SCORE')}:&nbsp;
                  <strong>{profileScore.EONScore || 0}</strong>
                </ContFlexLeft>
              </Col>
            )}
            <Col className="">
              <ContFlexRight>
                Contratti POD:&nbsp;<strong>{totPod}</strong>&nbsp; | Contratti
                PDR:&nbsp;<strong>{totPdr}</strong>
              </ContFlexRight>
            </Col>
          </Row>

          <Row className="search-add-cont m-t-20" style={{ rowGap: '1rem' }}>
            {profile.type === 'EON_ENERGY' && (
              <Col xs={12} style={{ marginBottom: 30 }}>
                <Button
                  href={`/examples/Template_Contratti.csv`}
                  target="_blank"
                  color="primary"
                >
                  SCARICA TEMPLATE IMPORTAZIONE
                </Button>
                <Button
                  href={`/documentation/Extra_Importazione_contratti.pdf`}
                  target="_blank"
                  color="primary"
                  style={{ marginLeft: '2rem' }}
                >
                  SCARICA DOCUMENTAZIONE IMPORTAZIONE
                </Button>
                <ExportCSVButton data={formattedData} />
              </Col>
            )}
            {!(profile && profile._id) && (
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.admin }}
                  onFilter={({ search }) => onFilter({ admin: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
            )}
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CUSTOMER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.pod_pdr }}
                onFilter={({ search }) => onFilter({ pod_pdr: search })}
                label={t('SEARCH:POD_PDR')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.condo_vat_cf }}
                onFilter={({ search }) => onFilter({ condo_vat_cf: search })}
                label={t('SEARCH:TAX_CODE')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.condo_admin_cf }}
                onFilter={({ search }) => onFilter({ condo_admin_cf: search })}
                label={t('SEARCH:ADMIN_VAT_CODE')}
              />
            </Col>

            <Col sm="12" md="6" lg="3" className="select-wrapper">
              <ContFilterYear
                className="no-width"
                style={{ marginBottom: '0' }}
              >
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_SIGNIN_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectContractSigningYear"
                        name="contractSigningYear"
                        value={query && query.contractSigningYear}
                        onChange={({
                          target: { value: contractSigningYear },
                        }) => {
                          onFilter({ contractSigningYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col sm="12" md="6" lg="3" className="select-wrapper">
              <ContFilterYear
                className="no-width"
                style={{ marginBottom: '0' }}
              >
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_SIGNIN_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="contractSigningMonth"
                        name="contractSigningMonth"
                        disabled={!query.contractSigningYear}
                        value={query && query.contractSigningMonth}
                        onChange={({
                          target: { value: contractSigningMonth },
                        }) => onFilter({ contractSigningMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col sm="12" md="4" lg="3" xl="3" className="select-wrapper">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>Tipologia cliente</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="customerType"
                        name="customerType"
                        value={customerType}
                        onChange={({ target: { value: customerType } }) =>
                          onFilter({ customerType })
                        }
                      >
                        <option value="" defaultChecked>
                          Tutti
                        </option>
                        <option value="condos">Condomini</option>
                        <option value="customerExtra">Clienti privati</option>
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            {profile.type === 'EON_ENERGY' && (
              <Col sm="6" md="6" lg="3" xl="3">
                <FormGroup style={{ marginTop: '1rem' }}>
                  <CustomInput
                    type="file"
                    id="importFilee"
                    name="importFilee"
                    onChange={(event) => handleImportFile(event)}
                    accept=".csv"
                  />
                  <Label for="selectFile">
                    {t('ENERGY_CONTRACT:CHOOSE_FILE')}
                    &nbsp;({t('ENERGY_CONTRACT:MAX_UPLOAD_FILE_SIZE')})
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
        </section>

        <section className="m-t-20">
          <ContractsTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onCondoUpdate={onCondoUpdate}
            onChangeAdmin={onChangeAdmin}
            profile={profile}
            onExtendAdminManagement={onExtendAdminManagement}
            truncateTable={profile && profile._id}
            checkVatCode={checkVatCode}
            energyUser={profile.type === 'EON_ENERGY'}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit: limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default ContractsPresentational;

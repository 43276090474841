import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import {
  H4Styled,
  VerticalSpacing,
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
  BtnOutlineOrange,
  ContFilterCheckbox,
  ContFilterYear,
  ContPaginationPage,
} from '../../styled-components';
import Table from './Table';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import CSVCompensiExport from './CSVCompensiExport';
import SearchInput from '../../commons/SearchInput';
import ModalInvoiceRef from '../ProfileDetail/Royalties/ModalInvoiceRef';
import { FormGroup } from 'reactstrap';
import CustomInput from 'reactstrap/lib/CustomInput';
import TotalTr from './TotalTr';
import Label from 'reactstrap/lib/Label';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import createPrintablePage from './createPrintablePage';
import variables from '../../variables';

function DashboardDetailCompensiStudioPresentational({
  data = [],
  dataExport = [],
  total = 0,
  year,
  query,
  onFilter,
  history,
  selectedInvoices = [],
  onSelectChange,
  onSelectAll,
  fetchCompensiExport,
  isReady = false,
  setIsReady,
}) {
  const [t] = useTranslation('DASHBOARD');
  const [allColumns, setAllColumns] = useState(false);

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:DASHBOARD')}
            icon={<IconDashboard fill={variables.white} />}
          />
        </section>

        <section>
          <Row>
            <Col md="12" lg="6">
              <ContFlexLeft>
                <BtnRadiusWhite onClick={() => history.replace('/dashboard')}>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>

                <H4Styled>{t('STUDIO_ROYALTY:TITLE')}</H4Styled>
              </ContFlexLeft>
            </Col>
            <Col md="12" lg="6">
              <ContFlexRight className="actions-m-resp">
                <CSVCompensiExport
                  allColumns={allColumns}
                  data={dataExport}
                  selectedInvoices={selectedInvoices}
                  year={year}
                  fetchCompensiExport={fetchCompensiExport}
                  isReady={isReady}
                  setIsReady={setIsReady}
                >
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVCompensiExport>

                <ContFilterCheckbox style={{ paddingLeft: '20px' }}>
                  {/*<CustomInput*/}
                  {/*  type="checkbox"*/}
                  {/*  id="columnCheckbox"*/}
                  {/*  label={t('COMMON:CSV_EXPORT_ALL_COLUMNS')}*/}
                  {/*  inline*/}
                  {/*  checked={allColumns}*/}
                  {/*  onChange={({ target: { checked } }) => {*/}
                  {/*    setAllColumns(checked);*/}
                  {/*  }}*/}
                  {/*/>*/}
                </ContFilterCheckbox>
              </ContFlexRight>
            </Col>
          </Row>

          <VerticalSpacing>
            <Form>
              <Row>
                <Col sm="6" md="6" lg="4" xl="4">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>{t('COMMON:YEAR')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="selectYear"
                            name="year"
                            value={query && query.year}
                            onChange={({ target: { value: year } }) => {
                              onFilter({ year });
                            }}
                          >
                            {generateYearOptions(true)}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.admin }}
                    onFilter={({ search }) => onFilter({ admin: search })}
                    label={t('SEARCH:ADMINISTRATOR')}
                  />
                </Col>
              </Row>
            </Form>

            <br />

            <Table
              data={data}
              total={total}
              onFilter={onFilter}
              onSelectChange={onSelectChange}
              selectedInvoices={selectedInvoices}
              query={query}
              onSelectAll={onSelectAll}
              bottomRow={<TotalTr data={data} />}
            />
          </VerticalSpacing>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default DashboardDetailCompensiStudioPresentational;
